import React from 'react';
import './FinishOverview.scss';
import {splitFromField} from '../utils/displayLogic';
import {generateDayDateTimeString} from '../utils/displayLogic';
import ListView from 'src/utils/ListView/ListView';
import GameHistory, {SolutionLevel} from '../logic/GameHistory';
import {SubmittedSolution} from '../logic/GameHistory';
import ExplainText from './ExplainText';
import LevelView from '../LevelView/LevelView';
import MailSVG from 'src/assets/PhishingQuiz/E-Mail.svg';
import PhishingMailSVG from 'src/assets/PhishingQuiz/Phishing-E-Mail.svg';

interface FinishOverviewProps {
  gameHistory: GameHistory,
}

/**
 * The finish Overview of answers and mails
 */
class FinishOverview extends React.Component<FinishOverviewProps> {
  /** Generic constructor **/
  constructor(props: FinishOverviewProps) {
    super(props);
  }

  mailAnswerDetails = (mail?: SubmittedSolution) => {
    // do nothing for now but show mail info in further versions
    console.log(mail);
  }

  createSolutions =():JSX.Element[] => {
    const resultLevels: JSX.Element[] = [];
    this.props.gameHistory.solutions.map((level, index) => {
      resultLevels.push(this.createListForLevel(level, index));
    });
    return resultLevels;
  }

  // create a list consisting of multiple levels
  createListForLevel = (level:SolutionLevel, index:number): JSX.Element => {
    return (<div key={index}>
      <LevelView currentLevelNumber={level.levelNumber}
        maxLevels={this.props.gameHistory.solutions.length-1}
        title={level.levelTitle}/>
      <ListView<SubmittedSolution> list={level.solutions}
        onClick={this.mailAnswerDetails}
        renderEntry={this.displayListEntry}/>
    </div>);
  }

  displayListEntry = (entry: SubmittedSolution): JSX.Element => {
    const dateTime = generateDayDateTimeString(entry.mail.date);
    return (
      <div className={`OverviewMailItem
        ${ entry.isCorrect? 'CorrectMailItem': 'WrongMailItem'}`}>
        <div className="CorrectnessIcon">
          {entry.isCorrect? '✓ ': '✗ '}
        </div>
        <div className="PhishingIdicator">
          <img className="PhishingIdicatorIcon"
            src={entry.mail.isPhishing? PhishingMailSVG : MailSVG}
            alt={entry.mail.isPhishing? 'Phishing Icon': 'Mail Icon'}/>
        </div>
        <div className="MailHeadContent">
          <div className="From" >{splitFromField(entry.mail.from)}</div>
          <div >{entry.mail.subject}</div>
          <div >{dateTime}</div>
        </div>
      </div>
    );
  }

  /** Plain render function **/
  render() {
    return (
      <div className="FinishOverview">
        <div className="OverviewMailList">
          {this.createSolutions()}
        </div>
        <ExplainText/>
      </div>
    );
  }
}

export default FinishOverview;
