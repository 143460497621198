import React from 'react';

import './SecurityCardsExplainPage.scss';
import ExplainPage from 'src/utils/ExplainPage/ExplainPage';
import IntroGif from '../../../assets/SecurityCards/SecurityCardsIntro.gif';


export interface SecurityCardsExplainPageProps {
  clickEvent: () => void,
}

/** The Explain Page, which can be displayed
 * just before the Quiz starts
 **/
class SecurityCardsExplainPage extends
  React.Component<SecurityCardsExplainPageProps> {
  /** Constructor taking a onClick function for the button**/
  constructor(props: SecurityCardsExplainPageProps) {
    super(props);
  }

  /** Render The Explain text **/
  render() {
    return (
      <div>
        <ExplainPage
          title="SecurityCards Anleitung"
          clickEvent={this.props.clickEvent}>
          <div className="ExplainText">
            <div className='IntroGif'>
              <img src={IntroGif} alt='Intro' height={405} width={850}/>
            </div>
            In SecurityCards spielen Sie einen Entscheidungsträger für ein
            Unternehmen.
            Abhängig davon für welches Unternehmen Sie sich entscheiden,
            werden Sie mit unterschiedlichen Ereignissen konfrontiert.
            <br /><br />
            Informationen über den Kontostand Ihres Unternehmens erhalten Sie
            durch die Geldanzeige in der Mitte des Spielfeldes.<br /><br />
            Ziel des Spiels ist es, den Kontostand im Plus zu halten.
            Dazu muss der Spielende auftretende Bedrohungen für das Unternehmen
            erkennen und passende Gegenmaßnahmen ergreifen.<br /><br />
            Hierfür stehen dem Spielenden unterschiedliche Karten zur Verfügung.
            Eine Karte kann eine Entscheidung oder eine präventive Investition
            in Schutzmaßnahmen darstellen.
            <br />
          Zusätzliche Karten können im
            <button className="DesignCardShopBTN">
              Zum KartenShop</button> erworben und aktiviert werden.
            <br />
          Der Spielablauf besteht aus einer bestimmten Anzahl von Kalenderwochen
        (KW), in denen Ereignisse auftreten.
          Das aktuelle Ereignis wird im oberen Bereich des Spiels
            angezeigt.
          Nach Auftreten eines Ereignisses muss der Spielende dieses mit Klick
          auf
            <button className="DesignBTN confirmBTN">OK</button>
          bestätigen.<br /><br />
          Danach kann durch Kauf oder Aktivierung von Karten auf das
          Ereignis reagiert werden.
          Ereignisse können temporär neue Karten generieren.
          Einige Ereignisse erfordern eine Reaktion
          durch Spielende und können nicht ignoriert werden.
          Spielbare Karten werden im unteren Bereich des Spielfeldes angezeigt.
          Karten können für eine Detailansicht angeklickt werden.<br /><br />
          In der Detailansicht kann durch Klick auf
            <button className="DesignBTN confirmBTN">Aktivieren</button>
            die Karte angewendet werden oder durch
            <button className="DesignBTN cancelBTN">Abbrechen</button>
          diese Ansicht beendet werden.
          </div>
        </ExplainPage>
      </div>
    );
  }
}

export default SecurityCardsExplainPage;
