import React from 'react';
import './FinView.scss';

interface FinViewProps {
  score: number,
  solvedChallenges: number,
  numberOfChallenges: number,
}

/** Fin View class **/
export default class FinView extends React.Component<FinViewProps> {
  /** Default Constructor **/
  constructor(props: FinViewProps) {
    super(props);
  }

  /** Restart the game on button click **/
  handleButton() {
    window.location.reload();
  }

  /** Render Funtion **/
  render() {
    return (
      <div className='FinView'>
        <h1>Spiel Ende</h1>
        <h2>Sie konnten {this.props.solvedChallenges} Challenges von
          insgesamt {this.props.numberOfChallenges +
          this.props.solvedChallenges} lösen.
        </h2>
        <h2>Erreichte Punktzahl: {this.props.score}</h2>
        <button onClick={this.handleButton}
          className='DesignBTN'>Neu Starten</button>
      </div>
    );
  }
}
