import React from 'react';
import {observer} from 'mobx-react';
import ChallengesListView from './ChallengesListView/ChallengesListView';
import BAKManLogo from 'src/assets/logo.svg';
import GameState from './logic/GameState';
import {screens} from './logic/GameState';
import ChallengeView from './ChallengeView/ChallengeView';
import ThreatOverview from './ThreatOverview/ThreatOverview';
import FinView from './FinView/FinView';
import './ThreatAttack.scss';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThreatAttackExplainPage from
  './ThreatAttackExplainPage/ThreatAttackExplainPage';


/**
 * The ThreatAttack Main Component
 */
@observer
class ThreatAttack extends React.Component {
  listView: React.RefObject<ChallengesListView>;
  gameState: GameState;
  /** constructor initializes GameState and calls getLevels function */
  constructor() {
    super({});
    this.listView = React.createRef();
    this.gameState = new GameState();
    let shortVersion = false;
    if (document.location.pathname.toLowerCase() == '/threatattack/short') {
      shortVersion = true;
    }
    this.gameState.load(shortVersion);
  }

  /** Render function **/
  render() {
    let rightSideView: JSX.Element;
    rightSideView = <div className="rightSideView"/>;

    if (this.gameState.activeScreen == screens.challenge) {
      if (this.gameState.clickedChallenge) {
        rightSideView = (
          <ChallengeView gameState={this.gameState}/>
        );
      }
    } else if (this.gameState.activeScreen == screens.overview) {
      rightSideView = <ThreatOverview gameState={this.gameState}/>;
    }

    let content: JSX.Element;
    if (this.gameState.activeScreen == screens.explainpage) {
      content = <ThreatAttackExplainPage clickEvent = {()=>
        this.gameState.showTutorialPage()}/>;
    } else if (this.gameState.activeScreen == screens.loading) {
      content = <div><p> Loading ... </p></div>;
    } else if (this.gameState.activeScreen == screens.fin) {
      content = (<FinView score={this.gameState.points}
        solvedChallenges={this.gameState.solvedChallenges}
        numberOfChallenges={this.gameState.challenges.size}/>);
    } else {
      content = (
        <div className="GameBody">
          <div className="ChallengeNavigation">
            {this.gameState.activeScreen != screens.overview?
            <button
              className={this.gameState.tutorialStep == 1?
                'ThreatOMatBTN ThreatOMatBTNGlow':
                'ThreatOMatBTN'}
              onClick={() =>{
                this.listView.current?.deselect();
                this.gameState.showOverview();
              }}
              data-arrow={this.gameState.tutorialStep == 1?
                '←': ''}>
            Zum Threat-O-Mat</button> :
            <button className="NavBtn"
              onClick={() =>{
                this.listView.current?.restore();
              }}
              disabled={!this.listView.current?.restoreable}
            >
            Zurück</button>
            }
            <div className='ChallengeList'>
              <h2>Challenges</h2>
              <ChallengesListView ref={this.listView}
                gameState={this.gameState}/>
            </div>
            <button onClick={() => {
              this.gameState.tutorialStep < 0?
              this.gameState.endGame(): this.gameState.endTutorial();
            }}
            className='NavBtn QuitBtn'>
              {this.gameState.tutorialStep >= 0? 'Tutorial überspringen':
              'Spiel vorzeitig Beenden'}</button>
          </div>
          {rightSideView}
        </div>
      );
    }

    return (
      <div className="ThreatAttack">
        <div className="GameHead">
          <a href="https://bakgame.de/">
            <img className="Logo" alt="BAK Logo" src={BAKManLogo}
              width="28" height="28"></img>
          </a>
          <span className="GameName">ThreatAttack</span>
          <span className="Points">Punkte: {this.gameState.points}</span>
        </div>
        {content}
        <ToastContainer autoClose={1500}/>
        <div className="GameFooter">
          <a className="FooterLink" href="https://www.bakgame.de/spiele/">
            Zurück zur Spieleauswahl</a>
          <br />
          <a className="FooterLink" href="https://www.bakgame.de/impressum/">
            Impressum</a>
          <br />
          <a className="FooterLink" href="https://www.bakgame.de/datenschutz/">
            Datenschutz</a>
        </div>
      </div>
    );
  }
}

export default ThreatAttack;
