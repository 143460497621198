import React from 'react';
import MainRouter from 'src/routing/MainRouter';

/**
 * The Main App Component
 */
class App extends React.Component {
  /** Plain render function **/
  render() {
    return (
      <div className="App">
        <MainRouter />
      </div>
    );
  }
}

export default App;
