import React from 'react';
import GameState from 'src/components/SecurityCards/logic/GameState';
import CardView from '../CardView/CardView';
import {
  ButtonBack, ButtonNext,
  CarouselProvider, DotGroup, Slide, Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './CardSlider.scss';
import Skill from '../logic/cards/Skill';
import {observer} from 'mobx-react';

interface SliderProps {
  gameState: GameState,
  handleCardSelect: (skill: Skill) => void
}

/** Slider Component for Cardviews*/
@observer
class CardSlider extends React.Component<SliderProps> {
  /** adds slider buttons if slides
   *  exeed number of visible slides */
  addSliderButtons = (slideAmount: number) => {
    // add media query to select a variable visible slide amount
    // for small resoultions
    if (slideAmount > 5) {
      return <React.Fragment>
        <ButtonBack className='buttonBack'>&lt;</ButtonBack>
        <ButtonNext className='buttonNext'>&gt;</ButtonNext>
        <DotGroup className='dotGroup'></DotGroup>
      </React.Fragment>;
    } else {
      return;
    }
  }

  /** default render */
  render() {
    const slideAmount = this.props.gameState.skills.handCards.length;
    return (
      <CarouselProvider
        totalSlides={slideAmount}
        naturalSlideHeight={500}
        naturalSlideWidth={300}
        visibleSlides={slideAmount < 5 ?
          slideAmount : 5}>
        <div className='SliderContainer'>
          <Slider className='Slider'>
            {this.props.gameState.skills.handCards.map((skill, index) => {
              return (<Slide index={index} key={index}>
                <CardView
                  isMinified={true}
                  usable={
                    !this.props.gameState.activeEffects.isCardBlocked(skill)}
                  onClickFunction={this.props.handleCardSelect}
                  skill={skill} />
              </Slide>);
            })
            }
          </Slider>
          {this.addSliderButtons(slideAmount)}
        </div>
      </CarouselProvider>);
  }
}

export default CardSlider;
