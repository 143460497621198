import React from 'react';
import './ScoreView.scss';


interface ScoreViewProps {
  score: number,
}

/**
 * A Class to view a game store,
 * this can be embedded anny Where
 * in the Document.
 * The Score is defined as a number.
 */
class ScoreView extends React.Component<ScoreViewProps> {
  /** Constructor taking the score as Number **/
  constructor(props: ScoreViewProps) {
    super(props);
  }

  /** Render The score **/
  render() {
    return (<span className="GameStatus ScoreView">
      Punktzahl: {this.props.score}</span>);
  }
}

export default ScoreView;
