import React from 'react';
import {observer} from 'mobx-react';
import GameState, {GamePages} from '../logic/GameState';
import BAKManLogo from 'src/assets/logo.svg';
import ScoreView from 'src/utils/ScoreView/ScoreView';
import LevelView from '../LevelView/LevelView';
import './TopBar.scss';

interface TopBarProps {
  gameState: GameState,
}


/**
 * The top bar content
 */
@observer
class TopBar extends React.Component<TopBarProps> {
  /** Default constuctor **/
  constructor(props: TopBarProps) {
    super(props);
  }

  /** Render function **/
  render() {
    let skipButton = <></>;
    let levelView;
    let scoreView;

    if (this.props.gameState.page == GamePages.game &&
      this.props.gameState.currentLevelNumber !== undefined &&
      this.props.gameState.currentMailSet !== undefined) {
      levelView = (<LevelView
        currentLevelNumber={this.props.gameState.currentLevelNumber}
        maxLevels={this.props.gameState.maxLevels}
        title={this.props.gameState.currentMailSet.title}/>);

      scoreView = <ScoreView score={this.props.gameState.score} />;

      if (this.props.gameState.currentMailSet.title == 'Tutorial') {
        skipButton = (<button className='DesignBTN'
          onClick={()=>this.props.gameState.selectNextLevel()}>
          Tutorial Überspringen
        </button>);
      }
    }

    return (
      <div className="TopBar">
        <a href="https://bakgame.de/">
          <img className="Logo" alt="BAK Logo" src={BAKManLogo}
            width="28" height="28"></img>
        </a>
        <span className="GameName">PhishingQuiz</span>
        <div className="TopBarInfo">
          {skipButton}
          {levelView}
          {scoreView}
        </div>
      </div>
    );
  }
}

export default TopBar;
