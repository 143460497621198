import {getJSON} from 'src/utils/Requests/Requests';

/** API Prefix **/
const PATH_PREFIX = '/ThreatAttack/api/challenges/';


/** Interface defining the challenge format */
export interface IChallenge {
  id: string
  title: string
  description: string
  level: number
  threatIds: string[]
  available: boolean
}

/** function to get a challenge object */
export async function getChallenge(path: string) : Promise<IChallenge> {
  const challenge : IChallenge = await getJSON(PATH_PREFIX + path);
  return challenge;
}
