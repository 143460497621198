import {makeObservable, observable, action, computed} from 'mobx';
import MailSet from './MailSet';
import GameHistory from './GameHistory';
import Mail from './Mail';
import {toast} from 'react-toastify';

export enum GamePages {
  loading,
  explain,
  personalisation,
  game,
  finish,
}

/** Game State for PhishingQuiz **/
export default class GameState {
  activeFeedback = false;
  score = 0;
  page: GamePages = GamePages.loading;
  currentMailSet: MailSet | undefined;
  levels: MailSet[] = [];
  gameHistory: GameHistory = new GameHistory;
  hintVisible = false;
  hintWarningVisible = false;
  mailHeaderVisibleFor: Mail | undefined;
  firstName = 'Vorname';
  lastName = 'Nachname';
  addressation = 'Herr';
  currentLevelNumber = -1;
  maxLevels = 0;
  /** Constructor making it observable **/
  constructor() {
    makeObservable(this, {
      activeFeedback: observable,
      score: observable,
      page: observable,
      currentMailSet: observable,
      hintVisible: observable,
      hintWarningVisible: observable,
      mailHeaderVisibleFor: observable,
      firstName: observable,
      lastName: observable,
      addressation: observable,
      email: computed,
      currentMail: computed,
      submit: action,
      selectNextMail: action,
      selectNextLevel: action,
      startGame: action,
      init: action,
      openPersonalisationMask: action,
      switchFeedback: action,
      toggleHint: action,
      currentLevelNumber: observable,
      maxLevels: observable,
      phishingLinkClicked: action,
      toggleHintWarn: action,
      showMailHeader: action,
      setPersonalisation: action,
    });
  }

  /** personalisation parameters */
  setPersonalisation(firstName:string, lastName: string, addressation: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.addressation = addressation;
  }

  /** getter for current mail (shortcut) **/
  get currentMail() {
    return this.currentMailSet?.activeMail;
  }

  /** construct email from firstname and lastname */
  get email() {
    return `${this.firstName}.${this.lastName}@kmu.de`;
  }

  /** Submit a solution **/
  submit(isPhishing: boolean) {
    if (!this.currentMailSet) {
      throw Error('current mailset is null');
    }
    const {isCorrect, points} = this.currentMailSet.score(isPhishing);
    const mail = this.currentMailSet.activeMail;
    let maxPoints;
    if (mail != undefined) {
      if (mail.isPhishing) {
        maxPoints = this.currentMailSet.POINTS.PHISHING_MAIL_CORRECT;
      } else {
        maxPoints = this.currentMailSet.POINTS.TRUSTED_MAIL_CORRECT;
      }
      this.gameHistory.add({mail, isCorrect}, maxPoints);
      this.showPointToast(points, isCorrect);
      this.score += points;
      if (!isCorrect) {
        this.switchFeedback();
      } else {
        this.selectNextMail();
      }
    }
  }

  /** Remove current and use next Mail or level **/
  selectNextMail() {
    if (!this.currentMailSet) {
      throw Error('current mailset is null');
    }

    const mail = this.currentMailSet.activeMail;
    if (mail != undefined) {
      this.currentMailSet.deleteEntry(mail);
    }

    if (this.currentMailSet.mailData.length == 0) {
      toast.info('Level abgeschlossen',
          {autoClose: 2000, hideProgressBar: true});
      this.selectNextLevel();
    }
  }

  /** Function that finishes the review **/
  finishReview = () => {
    this.switchFeedback();
    this.selectNextMail();
  }

  /** Show point toasts **/
  showPointToast(points: number, isCorrect: boolean) {
    if (isCorrect) {
      toast.success(`✓ Korrekt: ${points} Punkte`, {
        hideProgressBar: true,
      });
    } else {
      toast.error(`✗ Falsch: ${points} Punkte`, {
        hideProgressBar: true,
      });
    }
  }

  /** set next level from levels array as current mail set
   * shows finish page if no level is left
   */
  selectNextLevel() {
    this.gameHistory.pushCurrentSolutionLevel();
    const next = this.levels.shift();

    if (next == undefined) {
      this.page = GamePages.finish;
      return;
    }
    this.activeFeedback = false;
    this.currentMailSet = next;
    this.currentLevelNumber ++;
    this.gameHistory.addSolutionLevel(
        this.currentMailSet.title,
        this.currentLevelNumber,
    );
  }

  /** Start the first level **/
  startGame() {
    this.page = GamePages.game;
    this.maxLevels = this.levels.length - 1;
    this.selectNextLevel();
  }

  /** Show the hint to the Mail set **/
  showHints = () => {
    if (!this.currentMail?.hintUsed) {
      this.toggleHintWarn();
    } else {
      this.toggleHint();
    }
  }

  /** Set the state initialized and ready to use **/
  init() {
    this.page = GamePages.explain;
  }

  /** open personalisation Mask */
  openPersonalisationMask() {
    this.page = GamePages.personalisation;
  }

  /** Switch active feedback state (instant feedback on/off) */
  switchFeedback() {
    this.activeFeedback = !this.activeFeedback;
  }

  /** Toggle warning, that hints cost points **/
  toggleHintWarn() {
    this.hintWarningVisible = ! this.hintWarningVisible;
  }

  /** Set the hint to the current Mail **/
  toggleHint() {
    if (! this.currentMailSet) {
      throw Error('Current Mail Set is undefined.');
    }
    this.hintVisible = !this.hintVisible;
    if (this.hintVisible && !this.currentMail?.hintUsed) {
      this.currentMail?.useHint();
      toast.info(`Tipp genutzt ${
        this.currentMailSet?.POINTS.HINT_COSTS} Punkte`,
      {autoClose: 2000, hideProgressBar: true});
      this.score += this.currentMailSet.POINTS.HINT_COSTS;
    }
  }

  /** Show mail header overlay for the specified mail **/
  showMailHeader(mail: Mail) {
    this.mailHeaderVisibleFor = mail;
  }

  /** Hide mail header overlay **/
  closeMailHeader() {
    this.mailHeaderVisibleFor = undefined;
  }

  /** Update state when a link is clicked */
  phishingLinkClicked() {
    toast.error(`Phishing-Link: ${-5} Punkte`,
        {hideProgressBar: true});
    this.score -= 5;
  }
}
