import React from 'react';
import './ReadMore.scss';

import KMU from './Images/kmu.svg';
import Info from './Images/Info.svg';
import Game from './Images/Game.svg';
import Help from './Images/Helping.svg';
import Link from './Images/Link.svg';
import Tisim from './Images/cybersicher-logo.svg';
import Alarm from './Images/alarm-logo-noSubs-trans.png';
import Elite from './Images/ELITE-Logo.png';

/** ReadMore component to display additional information. **/
class ReadMore extends React.Component {
  /** default render function **/
  render() {
    return (
      <div id='Werbung'>
        <div id='WerbungTop'>
          <img id='WerbungLogo'
            src={Link}/>
          <p className='WerbungText'>
            Mehr zum Thema von anderen Förderungsprogrammen
          </p>
        </div>
        <div id='WerbungMain'>
          <a
            href='https://kmu-einfach-sicher.de/individuelles-bewusstsein-fuer-die-eigenen-daten-fuer-mehr-sicherheit/'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Info}/>
              <div className='Company'>
                <p className='CompanyText'>
                  KMU. Einfach Sicher.
                </p>
                <img className='CompanyLogo'
                  src={KMU}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Ein Bewusster Umgang mit den eigenen Daten für mehr Sicherheit
                </span>
                <p className='ContentText'>
                  Blog-Eintrag über Credential Stuffing und Datenleaks.
                </p>
              </div>
            </div>
          </a>
          <a
            href='https://weiterbildung.kmu-einfach-sicher.de/e-learning/kmu-einfach-sicher/surfen_mitarbeiter_02-01/units/31729/elearning/0/'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Info}/>
              <div className='Company'>
                <p className='CompanyText'>
                  KMU. Einfach Sicher.
                </p>
                <img className='CompanyLogo'
                  src={KMU}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Einfach Sicher - Einloggen
                </span>
                <p className='ContentText'>
                  30-minütiges E-Learning Modul über das Erkennen von
                  Nichtvertrauenswürdige Links und Webseiten.
                </p>
              </div>
            </div>
          </a>
          <a href='https://kmu-einfach-sicher.de/was-sind-eigentlich-phishing-ransomware/'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Info}/>
              <div className='Company'>
                <p className='CompanyText'>
                  KMU. Einfach Sicher.
                </p>
                <img className='CompanyLogo'
                  src={KMU}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Was ist eigentlich Ransomware, Phishing und co?
                </span>
                <p className='ContentText'>
                  Beispiel: Ransomware- Angriff, Firma wird
                  mit Lösegeld erpresst.
                </p>
              </div>
            </div>
          </a>
          <a href='https://alarm.wildau.biz/ls2'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Game}/>
              <div className='Company'>
                <p className='CompanyText'>
                  Awareness Labor KMU Informationssicherheit
                </p>
                <img className='CompanyLogo'
                  src={Alarm}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Hacking-Game
                </span>
                <p className='ContentText'>
                  Als Hacker versuchen, Zugang zu den Daten
                  einer Firma zu verschaffen.
                </p>
              </div>
            </div>
          </a>
          <a href='https://sec-o-mat.de/'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Help}/>
              <div className='Company'>
                <p className='CompanyText'>
                  Transferstelle Cybersicherheit
                </p>
                <img className='CompanyLogo'
                  src={Tisim}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Sec-O-Mat / TISiM Aktionsplan
                </span>
                <p className='ContentText'>
                  Sicherheitslücken im eigenen Betrieb erkennen.
                </p>
              </div>
            </div>
          </a>
          <a href='https://elite-projekt.de/'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Game}/>
              <div className='Company'>
                <p className='CompanyText'>
                  ELITE - ErLebbare IT-SichErheit durch mobile IT-Sec.PopUp-Labs
                </p>
                <img className='CompanyLogo'
                  src={Elite}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Das ELITE Projekt
                </span>
                <p className='ContentText'>
                  Einsatzszenarien in anpassbaren IT-Sicherheits-Umgebungen,
                  in denen IT-Angriffe und IT-Sicherheitsmaßnahmen simuliert
                  werden.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div id='WerbungBottom'>
          <p className='WerbungText'>
            Verlinkungen verweisen auf externe Inhalte für
            die BAKGame nicht verantwortlich ist.
          </p>
        </div>
      </div>
    );
  }
}

export default ReadMore;
