import React from 'react';
import './ListViewItem.scss';

/** An element for callbacks, the OnClick function,isActive and content */
interface ListViewItemProps<E> {
  element: E,
  onClick: (element: E) => void,
  isActive: boolean,
  content: JSX.Element,
}


/**
 * ListItem Class,
 * onClick method and isActive flag in props
 */
class ListViewItem<E> extends React.Component<ListViewItemProps<E>> {
  /** default constructor */
  constructor(props: ListViewItemProps<E>) {
    super(props);
  }

  /** default render method */
  render() {
    return (
      <li className={
        `ListViewItem ${this.props.isActive ? 'ActiveListItem': ''}`}
      onClick={() => this.props.onClick(this.props.element)}>
        {this.props.content}
      </li>
    );
  }
}

export default ListViewItem;
