import {IIncomeEffect} from 'src/api/SecurityCards/EffectsApi';
import Effect from './Effect';

/** extension to effect with income values */
export default class IncomeEffect extends Effect {
  amount:number;
  label: string;

  /** default constructor */
  constructor(data:IIncomeEffect) {
    super(data);
    this.amount = data.amount;
    this.label = data.label;
  }
}

/** typeguard for incomeEffects */
export function isIncomeEffect(effect:Effect):
effect is IncomeEffect {
  return (effect as IncomeEffect).amount != undefined;
}
