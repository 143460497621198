import Skill from './cards/Skill';
import {makeObservable, observable, action, computed} from 'mobx';
import GameData from './GameData';
import Effects from './effects/Effects';

/** observable container for all skill related data and functions */
export default class SkillContainer {
  data: GameData;
  activeEffects: Effects;
  persistentCards: Skill[] = [];
  selectedSkill: Skill | undefined;
  usedSkills: string[]= [];

  /** constructor requires existing gamedata
   *  and effects objects from gamestate */
  constructor(data:GameData, effects:Effects) {
    this.data = data;
    this.activeEffects = effects;
    makeObservable(this, {
      handCards: computed,
      persistentCards: observable,
      selectedSkill: observable,
      usedSkills: observable,
      addSkillCards: action,
      addSkill: action,
      removeSkillCard: action,
      selectSkill: action,
      addToUsedSkills: action,
      clearUsedSkills: action,
    });
  }

  /** Add Skills to Cards **/
  addSkillCards(skills: string[]) {
    for (let i = 0; i < skills.length; i++) {
      this.persistentCards.push(this.data.getSkill(skills[i]));
    }
  }

  /** add a single Skill **/
  addSkill(skill: Skill) {
    this.persistentCards.push(skill);
  }

  /** remove given skill from cards array */
  removeSkillCard(card: Skill) {
    const index = this.persistentCards.indexOf(card);
    if (index != -1) {
      this.persistentCards.splice(index, 1);
    } else {
      this.activeEffects.removeCardEffect(card);
    }
  }

  /** concat persistent and effectCards */
  get handCards() {
    return this.persistentCards.concat(this.activeEffects.effectSkills);
  }

  /** Select a skill for detail view **/
  selectSkill(skill: Skill) {
    this.selectedSkill = skill;
  }

  /** adds the passed skills name to usedSkills */
  addToUsedSkills(skill:Skill) {
    this.usedSkills.push(skill.name);
  }

  /** empties usedSkills array */
  clearUsedSkills() {
    this.usedSkills = [];
  }
}
