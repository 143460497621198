import {IThreat} from 'src/api/ThreatAttack/ThreatApi';

/** Threat class **/
class Threat {
  id: string;
  name: string;

  /** Copy Threat from interface **/
  constructor(template:IThreat) {
    this.id = template.id;
    this.name = template.name;
  }

  /** Deep copy for threat **/
  copy(): Threat {
    const ithreat: IThreat = {
      id: this.id,
      name: this.name,
    };
    return new Threat(ithreat);
  }
}

export default Threat;
