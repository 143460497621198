import {IProbabilityEffect} from 'src/api/SecurityCards/EffectsApi';
import {Tag} from 'src/api/SecurityCards/SecurityEventsApi';
import Effect from './Effect';

/** extension to effect with probability values */
export default class ProbabilityEffect extends Effect {
    probability:number;
    tag:Tag;
    /** default constructor */
    constructor(data:IProbabilityEffect) {
      super(data);
      this.probability = data.probability;
      this.tag = data.tag;
    }
}

/** typeguard for probabilityEffects */
export function isProbabilityEffect(effect:Effect):
effect is ProbabilityEffect {
  return (effect as ProbabilityEffect).probability != undefined;
}
