import {getJSON} from 'src/utils/Requests/Requests';
import {Tag} from './SecurityEventsApi';

/** IEffect interface representing any kind of effect data**/
export interface IEffect {
  name: string,
  duration: number,
}

export interface ICardEffect extends IEffect {
    skillName: string,
}

export interface IProbabilityEffect extends IEffect {
    probability: number,
    tag: Tag,
}

export interface IIncomeEffect extends IEffect {
  amount: number,
  label: string,
}

export interface IBlockEffect extends IEffect {
  blockAllReactive?: boolean,
  blockAllPreventive?: boolean,
  blockByTags?: string[],
  blockByName?: string[],
  blockReason: string,
}

/** API prefix **/
const PATH_PREFIX = '/SecurityCards/api/effects/';

/** fetch effect jsons from server and return array of effect objects */
export async function getEffects(effectNames: string[]): Promise<IEffect[]> {
  const effects: IEffect[] = [];
  for (let i = 0; i < effectNames.length; i ++) {
    const effect:IEffect = await getJSON(PATH_PREFIX +
       effectNames[i] + '.json');
    effects.push(effect);
  }
  return effects;
}

/** Typeguard for IBlockEffect **/
export function isIBlockEffect(effect: IEffect): effect is IBlockEffect {
  const data = effect as IBlockEffect;
  if (data.blockByName != undefined) {
    return true;
  }
  if (data.blockByTags != undefined) {
    return true;
  }
  if (data.blockAllReactive != undefined) {
    return true;
  }
  if (data.blockAllPreventive != undefined) {
    return true;
  }
  return false;
}
