import React from 'react';

import './MailHeaderView.scss';
import {MailIssues} from 'src/api/JsonMailApi';
import {getIssueText} from
  'src/components/PhishingQuiz/logic/InstantFeedbackText';
import DefaultAvatar from 'src/assets/PhishingQuiz/user.png';
import Paperclip from 'src/assets/PhishingQuiz/paperclip_cc0.svg';
import GameState from 'src/components/PhishingQuiz/logic/GameState';
import {observer} from 'mobx-react';

interface MailHeaderViewProps {
  gameState: GameState,
}

/**
 * PhishingQuiz MailView Component
 *
 * This displays an MailView using
 * a json, which contains email header
 * fields and a html body.
 */
@observer
class MailHeaderView extends React.Component<MailHeaderViewProps> {
  /** Constructor setting props:
   * Make sure that the mail is no user defined content
   * to avoid XSS in the Body View
   **/

  feedback: Map<string, string>;
  /** Default constructor **/
  constructor(props: MailHeaderViewProps) {
    super(props);
    if (this.props.gameState.currentMail == undefined) {
      throw new Error('Cannot display Mail Header for undefined');
    }
    // The not undefined assertion is broken in TS:
    // eslint-disable-next-line
    this.feedback = getIssueText(props.gameState.currentMail!);
  }

  /** checks if the issue is an issue of the current mail **/
  isIssue = (type: MailIssues) : string => {
    if (!this.props.gameState.activeFeedback) {
      return '';
    }

    if (!this.props.gameState.currentMail?.isIssueOfMail(type)) {
      return '';
    }

    if (this.props.gameState.currentMail?.isPhishing) {
      return 'ErrorTooltip--phishing';
    } else {
      return 'ErrorTooltip--regular';
    }
  }

  /** onClick Function for Attachment element
   * currently opens new tab with info page
   */
  onAttachmentClick = () => {
    if (this.props.gameState.currentMail?.isPhishing) {
      window.open('/PhishingQuiz/PhishingAttachment', '_blank');
    } else {
      window.open('/PhishingQuiz/TrustedAttachment', '_blank');
    }
  }

  /** A render function using the json data **/
  render() {
    let attachment = undefined;
    if (this.props.gameState.currentMail?.attachment) {
      attachment = (
        <div className={`${this.isIssue(MailIssues.attachment)} Attachment`}
          data-text={`${this.feedback.get('attachment')}`}
          onClick={this.onAttachmentClick}>
          <img src={Paperclip} alt='Büroklammer'></img>
          <div>{this.props.gameState.currentMail?.attachment}</div>
        </div>);
    }

    const currentIssues = this.props.gameState.currentMail?.issueTags ?? [];

    return (
      <div className="MailHeaderView">
        <div className="Subject">
          <span className={`${this.isIssue(MailIssues.topic)}`}
            data-text={`${this.feedback.get('topic')}`}>
            {this.props.gameState.currentMail?.subject}</span></div>
        <div className="MailHeaderInfo">
          <img className="FromAvatar" src={DefaultAvatar}/>
          <div className="HeaderDetailBlock">
            <div className="From">
              <span className={`${this.isIssue(MailIssues.address)}`}
                data-text={`${this.feedback.get('address')}`}>
                {this.props.gameState.currentMail?.from}</span>
            </div>
            <div className="DateTime">{
              this.props.gameState.currentMail?.dateTimeAsString
            }</div>
            <div className={`Receivers`}>
              <span className={`${this.isIssue(MailIssues.recipient)}`}
                data-text={`${this.feedback.get('recipient')}`}>
                {
                  currentIssues.includes(MailIssues.recipient)?
                  this.props.gameState.currentMail?.to:
                  this.props.gameState.email
                }</span>
            </div>
          </div>
          {attachment}
        </div>
      </div>
    );
  }
}

export default MailHeaderView;
