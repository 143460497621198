import React from 'react';
import {observer} from 'mobx-react';
import GameState from '../logic/GameState';
import MailListFeedbackOverlay from
  '../MailListView/MailListFeedbackOverlay/MailListFeedbackOverlay';
import MailListView from '../MailListView/MailListView';
import QuizContainer from './QuizContainer/QuizContainer';

interface QuizBodyProps {
  gameState: GameState,
}

interface QuizBodyState{
  maillistVisible : boolean,
}

/**
 * The Quiz Body
 */
@observer
class QuizBody extends React.Component<QuizBodyProps, QuizBodyState> {
  listView: React.RefObject<MailListView>;

  /** Default Constructor **/
  constructor(props: QuizBodyProps) {
    super(props);
    this.listView = React.createRef();
    this.state = {maillistVisible: false};
  }

  /** show /hide maillist **/
  toggleMaillist = () => {
    this.setState({maillistVisible: !this.state.maillistVisible});
  }

  /** Returns span parent or body */
  findSpanParent(htmlElement: HTMLElement) : HTMLElement {
    while (htmlElement.parentElement) {
      if (htmlElement.nodeName !== 'SPAN') {
        htmlElement = htmlElement.parentElement;
      } else {
        return htmlElement;
      }
    }
    return htmlElement;
  }


  /** Redirect links */
  phishingLinkClickEvent = (event: Event) : boolean => {
    if (event) {
      const isPhishingLink = this.findSpanParent(event.target as HTMLElement).
          className.indexOf('Err') !== -1;

      if (isPhishingLink) {
        this.props.gameState.phishingLinkClicked();
        window.open('/PhishingQuiz/PhishingPage', '_blank');
      } else {
        window.open('/PhishingQuiz/CompanyPage', '_blank');
      }
    }
    // blocks event propagation to the link when used with onclick
    return false;
  }

  /** Render function **/
  render() {
    return (
      <div className="QuizBody">
        <input type="checkbox" name="checkbox" id='MenuTrigger'
          className="MenuTrigger" checked={this.state.maillistVisible}
          onChange={this.toggleMaillist} />
        <label htmlFor="MenuTrigger" className="MenuLabel">
          <div></div>
        </label>
        <div className="MailList">
          <MailListFeedbackOverlay
            isActive={this.props.gameState.activeFeedback}
            isPhishing={this.props.gameState.currentMailSet?.
                activeMail?.isPhishing ?? true} />
          <h2>Posteingang</h2>
          <MailListView gameState={this.props.gameState}
            ref={this.listView} toggleCheckbox={this.toggleMaillist} />
        </div>
        <QuizContainer gameState={this.props.gameState}/>
      </div>
    );
  }
}

export default QuizBody;
