import React from 'react';
import email1 from 'src/assets/PhishingQuiz/email1.png';
import email2 from 'src/assets/PhishingQuiz/email2.png';
/** ExplainText Class which returns the ExplainText as React Component.
 */
export default class ExplainText extends React.Component {
  /** Render the text **/
  render() {
    return (
      <div className="ExplainText">
        <div>
          <h1>Woran erkennt man Phishing E-Mails?</h1>
          <details open>
            <summary>Gefälschte Absender-Adresse</summary>
            <p>
              Ein oft anzutreffendes Merkmal ist eine gefälschte
              Absender-Adresse. In den meisten
              Fällen ist dies daran zu erkennen, dass der angezeigte Name
              des Absenders nicht mit der E-Mail-Adresse
              übereinstimmt. Allerdings kann es auch vorkommen, dass die
              E-Mail-Adresse gefälscht ist.
            </p>
          </details>
          <details>
            <summary>Empfängerfeld</summary>
            <p>
              Wenn Sie mehrere E-Mail-Adressen empfangen, kann die
              Empfängeradresse darüber Aufschluss geben, ob der
              Kontext der E-Mail zutreffen kann.
            </p>
          </details>
          <details>
            <summary>Verdächtiger Betreff</summary>
            <p>
              Wenn der Betreff einer E-Mail bereits verdächtig erscheint,
              sollten Sie diese mit erhöhter Vorsicht betrachten.
            </p>
          </details>
          <details>
            <summary>Generalisierte Anreden</summary>
            <p>
              Eine allgemeine Anredeform, wie &bdquo;Ich grüße Sie,&ldquo;
              oder &bdquo;Guten Tag,&ldquo; in einer E-Mail, welche direkt an
              Sie adressiert ist, deutet auf eine automatisch erstellte E-Mail
              hin. Dies kann
              bereits ein erstes Anzeichen für eine Phishing E-Mail darstellen.
            </p>
          </details>
          <details>
            <summary>Aufforderung zu Rückmeldungen</summary>
            <p>
              Häufig enthalten Phishing E-Mails eine direkte oder indirekte
              Aufforderung sich zurückzumelden. Manchmal sollen dabei bereits
              personenbezogene Daten angegeben werden.
              Solche E-Mails dienen drei Zwecken: 1. Ihre E-Mail Adresse wird
              von Angreifern validiert, 2. die Angreifer vervollständigen
              Datensätze über Sie und 3. die Angreifer
              versuchen Sie in eine Konversation zu verwickeln und mit
              weiteren Tricks ihr Ziel zu erreichen.
            </p>
          </details>
          <details>
            <summary>Anhänge</summary>
            <p>
              Anhänge von E-Mails
              können zur Infektion
              Ihres Computers führen. Dies kann selbst bei Dokumenten
              mit typischen Datei-Endungen wie
              .xlsm, .docm, .xlsx, .docx  oder .pdf passieren.
              Daher sollten Sie vor dem Öffnen von Dateien Ausschau nach
              Phishing E-Mail Merkmalen halten. Generell gilt,
              öffnen Sie nur Anhänge aus vertrauenswürdigen
              Quellen und nutzen Sie immer die geschützte Ansicht, wenn
              möglich.
            </p>
          </details>
          <details>
            <summary>Rechtschreibfehler und Grammatik</summary>
            <p>
              Viele Phishing E-Mails enthalten entweder Fehler in
              Rechtschreibung und Grammatik
              oder sind ungewöhnlich formuliert. Solche Auffälligkeiten
              können ein Hinweis auf eine Phishing E-Mail sein. Diese
              Fehler entstehen dadurch, dass die E-Mails aus
              einer fremden Sprache ins Deutsche übersetzt werden oder
              von einem Nichtmuttersprachler verfasst werden.
            </p>
          </details>
          <details>
            <summary>Psychologischer Druck</summary>
            <p>
              Angreifer versuchen in Phishing E-Mails oftmals Sie unter
              Druck zu setzen. Dafür bedienen sie sich Mitteln wie
              Zeitdruck sowie direkte als auch indirekte
              Drohungen. Das soll impulsives Handeln fördern und das
              rationale Denkvermögen einschränken.
              Um dem vorzubeugen, sollten Sie immer einen kühlen Kopf
              bewahren. Machen Sie es zu einer Gewohnheit,
              immer gezielt nach Phishing-Anzeichen zu suchen, besonders
              bei dringenden E-Mails wie beispielsweise einer
              Kündigungsbestätigung.
            </p>
          </details>
          <details>
            <summary>Links</summary>
            <p>
              Verlinkungen in E-Mails können Sie auf zwei Arten täuschen.
              Zum einen kann die Adresse in der E-Mail nicht mit der
              eigentlichen Ziel-Adresse übereinstimmen.
              Das liegt daran, dass Verlinkungen einen Titel haben können,
              welcher von den Angreifern als eine andere Adresse verfasst
              wird. Zum anderen kann die Ziel-Webseite selbst eine
              vermeintlich echte Fälschung sein.
              Überprüfen Sie daher, bevor sie auf Links klicken die
              Zieladresse in der Linkvorschau. Diese wird beim Überfahren
              des Links mit der Maus eingeblendet und befindet sich
              entweder links unten oder direkt neben den Link.

              <img className="Email1" src={email1}/>
              <img className="Email2" src={email2}/>

              Wenn sie einen Link aus einer E-Mail folgen, sollten Sie
              die Webseite noch einmal in der Adresszeile ihres Browsers
              überprüfen. Handelt es sich um gängige Webseiten, sollten
              Sie statt die Verlinkung zu benutzen, diese
              selbst eingeben oder gegebenenfalls ein Lesezeichen
              verwenden. Ziel der Angreifer ist es dabei,
              Daten wie beispielsweise ihr Passwort im Falle eines
              Logins zu erhalten oder Sie zum Download
              einer Datei oder eines Programmes zu verleiten und diese
              zu öffnen.
            </p>
          </details>
        </div>
        <div>
          <h1>Wie gehe ich vor, wenn ich Opfer eines Phishing-Angriffes
            wurde?</h1>
          <details>
            <summary>Ändern Sie umgehend Ihr Passwort</summary>
            <p>
              Sofern Sie Opfer eines Phishing-Angriffes sind, besitzt
              der Angreifer vermutlich Ihre Anmeldedaten. Darunter auch
              Ihr Passwort in Klartext. Daher kann dieser sich
              beispielsweise unter Ihrer Identität bei diversen
              Onlineseiten authentifizieren. Um dies zu verhindern,
              sollten Sie umgehend auf allen Webseiten Ihr Passwort ändern,
              auf welchen Sie das entwendete Kennwort genutzt haben.
            </p>
          </details>
          <details>
            <summary>Lassen Sie Ihren Account sperren</summary>
            <p>
              Der Angreifer kann die entwendeten Daten nutzen, um sich bei
              wichtigen Behörden, wie zum Beispiel der Bank, als Sie
              auszugeben. Daher sollte der kompromittierte Account gesperrt
              werden, sofern es sich als notwendig erweist.
            </p>
          </details>
          <details>
            <summary>Melden Sie den Phishing-Vorfall</summary>
            <p>
              Angreifer wenden üblicherweise Phishing auf große Gruppen an
              Personen an. Daher ist es wahrscheinlich, dass weitere Personen
              ins Visier genommen werden. Um diese zu schützen, sollten Sie
              Ihren Phishing-Vorfall melden. Dadurch wird die Ausbreitung
              gestoppt und der Schaden minimal gehalten.
            </p>
          </details>
          <details>
            <summary>Schalten Sie relevante
              Strafverfolgungsbehörden ein</summary>
            <p>
              Ausgehend vom Ausmaß des Schadens, ist es empfehlenswert
              eine Anzeige bei den entsprechenden Strafverfolgungsbehörden
              aufzugeben. Dies ist dann besonders wichtig, wenn
              Industriestandards oder gesetzliche Vorgaben Sie verpflichten,
              Phishing-Vorfälle innerhalb einer bestimmten Frist zu melden.
            </p>
          </details>
          <details>
            <summary>Vermeiden Sie zukünftige Angriffe</summary>
            <p>
              Nutzen Sie die neuen gewonnenen Kenntnisse über die
              Phishing-Angriffe des Angreifers, um sich in Zukunft
              vor solchen Angriffen zu schützen. Gegebenenfalls ist es
              empfehlenswert zusätzlich eine Schulung im Bezug auf
              Phishing-Angriffe zu machen.
            </p>
          </details>
        </div>
      </div>);
  }
}
