import {makeObservable, observable, action} from 'mobx';
import Threat from './Threat';
import {popRandom} from 'src/utils/Random/random';
import {getThreat} from 'src/api/ThreatAttack/ThreatApi';

/** Class Containing Threat pools
 *  One pool contains all threats
 *  one pool contains staged ones (those might
 *  be drawn by the threat meter)
 *  and one contains the available threats.
 */
class ThreatPools {
  allThreats: Map<string, Threat> = new Map();
  stagedThreats: Threat[] = [];
  availableThreats: Threat[] = [];

  /** Constructor binding the observable **/
  constructor() {
    makeObservable(this, {
      availableThreats: observable,
      stagedThreats: observable,
      makeThreatAvailable: action,
      stageThreats: action,
      consumeThreat: action,
      load: action,
    });
  }

  /** Stage threats by ID, they might be drawn **/
  stageThreats(threatIDs: string[]) {
    for (const threatID of threatIDs) {
      const threat = this.allThreats.get(threatID);
      if (threat == undefined) {
        throw Error(`Threat with ID: ${threatID} Does not exist`);
      }
      this.stagedThreats.push(threat.copy());
    }
  }


  /** Unsage logic **/
  unstageThreats(threadIDs: string[], exclude: string[]) {
    for (const id of threadIDs) {
      if (exclude.includes(id)) {
        continue;
      }
      let i = 0;
      for (i = 0; i < this.stagedThreats.length; i++) {
        if (this.stagedThreats[i].id == id) {
          break;
        }
      }
      this.stagedThreats.splice(i, 1);
    }
  }

  /** Add a threat to the users pool **/
  makeThreatAvailable() {
    const newThreat = popRandom(this.stagedThreats);
    this.availableThreats.push(newThreat.copy());
  }

  /** Consume a threat for a challenge **/
  consumeThreat(threatID: string) : boolean {
    const index = this.availableThreats.findIndex((i) => i.id === threatID);
    if (index != -1) {
      this.availableThreats.splice(index, 1);
      return true;
    } else {
      return false;
    }
  }

  /** Asyncrone Load Data **/
  async load(fileNames: string[]) {
    for (let i = 0; i < fileNames.length; i++) {
      const iThreat = await getThreat(fileNames[i]);
      const threat = new Threat(iThreat);
      this.allThreats.set(threat.id, threat);
    }
  }
}

export default ThreatPools;
