import GameState from 'src/components/SecurityCards/logic/GameState';
import Skill from './cards/Skill';
import Event from './cards/Event';
import {Tag} from 'src/api/SecurityCards/SecurityEventsApi';

export interface Turn {
  event: Event,
  usedSkills: Skill[],
  money: number,
}

/**
 * history object saves the used skills and events for each turn
 */
export default class History {
  history: Turn[] = [];
  eventMap = new Map<Event, Skill[]>();

  /**
   * creates map of events to appliable skills
   * @param gameState
  */
  createEventMap(gameState: GameState) {
    const skills = gameState.shop.shopableSkills.map(
        (value)=>gameState.data.getSkill(value));
    for (const turn of this.history) {
      const eventTags = turn.event.tags;
      const matchingSkills: Skill[] = [];
      if (turn.event.category == 'harmless') {
        continue;
      }
      for (const skill of skills) {
        if (skill.tags.some((elem:Tag)=>eventTags.includes(elem))) {
          matchingSkills.push(skill);
        }
      }
      if (matchingSkills.length > 0) {
        this.eventMap.set(turn.event, matchingSkills);
      }
    }
  }

  /**
   * adds data for current turn to history array
   * @param gameState
   */
  addTurn(gameState: GameState) {
    if (gameState.activeEvent != undefined) {
      const turn: Turn = {
        'event': gameState.activeEvent,
        'usedSkills': gameState.skills.usedSkills.map(
            (value) => gameState.data.getSkill(value)),
        'money': gameState.money,
      };
      this.history.push(turn);
    }
  }
}
