import {ICardEffect} from 'src/api/SecurityCards/EffectsApi';
import Skill from '../cards/Skill';
import Effect from './Effect';

/** Effect extension with connected Card */
export default class CardEffect extends Effect {
    skill:Skill;
    /** default constructor */
    constructor(data:ICardEffect, skill:Skill) {
      super(data);
      this.skill = skill;
    }
}

/** typeguard for cardEffects */
export function isCardEffect(effect:Effect): effect is CardEffect {
  return (effect as CardEffect).skill != undefined;
}
