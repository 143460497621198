import Mail from './Mail';

const PHISHING_TEXTS: Map<string, string> = new Map([
  ['address', `Eine unseriöse Sender-Adresse kann ein Hinweis
    auf Phishing sein. Achten Sie hier auf die Namensgebung 
    sowie ob der Name, falls vorhanden, mit der Mailadresse übereinstimmt.`],
  ['linkaddress', `Links, welche zu ungewöhnlichen Adressen 
    verlinken, sind häufig ein erstes Anzeichen für eine 
    Phishingmail. Dabei werden verschiedene 
    Techniken genutzt, um diese Links zu verstecken oder zu maskieren.`],
  ['links', `Links, welche in Fremdsprachen verfasst sind, 
    ein fremdes Alphabet (z.B. Kyrillisch) benutzen oder auf 
    merkwürdige Domains verweisen, die nicht zur E-Mail passen, sind
    ein Hinweis auf mögliche Phishing-Versuche.`],
  ['pressure', `Versuche, den Leser einer E-Mail unter Druck zu setzen, 
    können ein Anzeichen für Phishing sein.`],
  ['typos', `Ungewöhnliche Formulierungen sowie Rechtschreib- und 
    Grammatikfehler können ein Hinweis auf Phishing sein.`],
  ['recipient', `Wenn Sie mehrere Mailadressen empfangen,
    kann die Empfängeradresse darüber Aufschluss geben, ob der
    Inhalt der E-Mail im Kontext zutreffen kann.`],
  ['topic', `Ein unprofessioneller Betreff kann bereits ein erstes Anzeichen
    für versuchtes Phishing sein.`],
  ['addressation', `Eine allgemeine Anredeform kann
    ein Anzeichen für eine Phishingmail sein.`],
  ['attachment',
    `Anhänge, wie dieser, enthalten häufig Schadcode,
    der ihren Rechner infizieren kann.`],
  ['tutorial', `In den Tooltipps finden Sie hilfreiche Tipps zur Erkennung
    von Phishingmails.`],
]);

const TRUSTED_TEXTS: Map<string, string> = new Map([
  ['address', `Überprüfen Sie die Absenderadresse. Im Zweifelsfall
    können Sie nach der Adresse mit einer Suchmaschine suchen.`],
  ['linkaddress', `Überprüfen Sie die Linkadressen. Wenn diese
    auf die Seite des Absenders verweisen ist dies kein
    Hinweis auf Phishingmails.`],
  ['links', `In vielen Mails sind Links enthalten, vertrauenswürdige
    Anbieter verlinken jedoch meist auf Ihre eigenen Seiten. Zur
    Überprüfung der Seite können Sie den ersten Teil des Links mit
    einer Suchmaschine suchen.`],
  ['pressure', `Seriöse Absender setzen Sie deutlich weniger unter
    Druck als viele Betrüger. Insbesondere werden Webshops und
    Zahlungsdienstleister Sie nicht dazu auffordern, auf Links zu
    Klicken, um die Sperrung Ihres Kontos zu verhindern.`],
  ['typos', `Rechtschreibfehler können auch in legitimen Mails vorkommen,
    gerade geschäftliche Mails weisen jedoch eine gewisse Qualität auf.`],
  ['recipient', `Wenn Sie mehrere Mailadressen empfangen,
    kann die Empfängeradresse darüber Aufschluss geben, ob der
    Inhalt der E-Mail im Kontext zutreffen kann.`],
  ['topic', `Ein professioneller und passender Betreff kann ein Anzeichen
    für vertrauenswürdige Emails sein. Er allein sollte jedoch
    nicht als Kategorie zum erkennen von Phishingmails verwendet werden.`],
  ['addressation', `Wenn Sie persönlich in einer Mail angesprochen werden,
    ist es ein Zeichen, dass es sich entweder um eine legitime Email oder eine
    gut gemachte Phishingmail handelt.`],
  ['attachment',
    `Anhänge können genutzt werden, um Schadcode zu verteilen. Es gibt jedoch
    auch vertrauenswürdige Anhänge, überprüfen Sie die Dateiendung und
    erlauben Sie nicht das Ausführen von Code.`],
  ['tutorial', `In den Tooltipps finden Sie hilfreiche Tipps zur Erkennung
    von vertrauenswürdigen Mails.`],
]);


/** get Issue text to the mail for Instant Feedback **/
export function getIssueText(mail: Mail) {
  if (mail.isPhishing) {
    return PHISHING_TEXTS;
  } else {
    return TRUSTED_TEXTS;
  }
}
