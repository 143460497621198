import React from 'react';
import './PhishingPage.scss';
import susAttach from 'src/assets/PhishingQuiz/suspiciousAttachment.svg';

/** Phishing landing Page**/
class PhishingAttachmentPage extends React.Component {
  /** Simple render function **/
  render() {
    return (
      <div className="PhishingPage">
        <div className="content">
          <img src={susAttach} alt="Ausrufezeichen"/>
          <h1>Diese Mail enthält einen Phishing Anhang</h1>
          <div>
            <h2>
              Weshalb waren Sie der Meinung, dass dieser Anhang
              legitim ist?
            </h2>
            <video loop autoPlay muted>
              <source src="video/excel_attack.mp4" type="video/mp4"/>
              Video tag nicht supported
            </video>
            <h3 >Achtung! Bei bestimmten Voreinstellungen
            werden aktive Inhalte automatisch aktiviert, ohne dass eine
            Bestätigung erforderlich ist.
            Neben Excel Dateien können auch andere
            Formate wie PDF betroffen sein.</h3>
            <h3>
              Öffnen Sie nur Anhänge, wenn sie sich sicher sind,
              dass diese legitim sind.
            </h3>
            <h3>
              Potenziell können fast alle Anhänge einer
              Phishing-Mail gefährlich sein.
              Das Öffnen von schädlichen Anhängen kann Angreifern unter
              anderem ermöglichen:
            </h3>
            <div>
              <ul>
                <li>
                  Beliebige Aktionen auf Ihrem Computer auszuführen.
                  Hierdurch können Kriminelle, Ransomware,
                  oder andere schädliche
                  Anwendungen auf ihrem Computer installieren.
                  Häufig kann durch derartige Angriffe die
                  gesamte IT eines Unternehmens lahmgelegt werden.
                  Anhänge, die davon besonders betroffen sind,
                  sind ausführbare Dateien, wie .exe.
                  Aber auch pdf Dateien oder Office
                  Dokumente wie .docx/.docm oder .xlsx/.xlsm
                  sind häufig genutzte Einfalltore.
                </li>
                <li>
                  Weitere Informationen über Sie erhalten,
                  die E-Mail wird für spätere
                  Angriffe validiert. Hierzu reicht es aus,
                  dass ihr Mailclient Bilder
                  anzeigt oder Sie Anhänge mit Onlineinhalten öffnen.
                </li>
              </ul>
              <h3>
                Sollten Sie beim Öffnen eines E-Mail
                Anhangs nach der Erlaubnis für
                aktive Inhalte oder Macros gefragt werden,
                sollten sie diese verweigern.
              </h3>
            </div>
          </div>
          <button className='CloseWindowBTN' onClick={ () => {
            window.close();
          }}>Schließen</button>
        </div>
      </div>
    );
  }
}

export default PhishingAttachmentPage;
