/** Split the recipient of the mail **/
export function splitFromField(email: string): string {
  let res = '';
  if (email.includes('<')) {
    const i: number = email.lastIndexOf('<');
    res = email.substring(0, i);
  } else {
    res = email;
  }
  return res;
}

/**
 *
 * @param date A date object
 * @returns string: "weekday in 2 chars dd:MM"
 */
export function generateDayDateString(date: Date): string {
  let day = '';
  switch (date.getUTCDay()) {
    case 0:
      day = 'So';
      break;
    case 1:
      day = 'Mo';
      break;
    case 2:
      day = 'Di';
      break;
    case 3:
      day = 'Mi';
      break;
    case 4:
      day = 'Do';
      break;
    case 5:
      day = 'Fr';
      break;
    case 6:
      day = 'Sa';
      break;
    default:
      console.error('Unexpected day of the week in date object: ', date);
  }
  return day + ', ' + date.getUTCDate() + '.' + (date.getUTCMonth() + 1) + '.';
}

/**
 *
 * @param date A date object
 * @returns string: "weekday in 2 chars dd:MM, hh:mm"
 */
export function generateDayDateTimeString(date: Date):string {
  return generateDayDateString(date)+' '+date.toTimeString().substring(0, 5);
}
