import {getJSON} from 'src/utils/Requests/Requests';

/** Interface defining the json mail format
 * This must match the python generated jsons for parsing
 * Important: Never use any kind of user defined content for body entrie
 * else might cause XSS
 **/
export interface JsonMail {
  to: string,
  subject: string,
  date: Date,
  from: string,
  replyTo: string,
  body: string,
  attachment?: string,
  isPhishing: boolean,
  issueTags: MailIssues[] | undefined,
  headers: string | undefined,
}

/** Tags, what might be wrong with the mail **/
export enum MailIssues {
  address = 'address',
  linkaddress = 'linkaddress',
  links = 'links',
  pressure = 'pressure',
  typos = 'typos',
  recipient = 'recipient',
  topic = 'topic',
  addressation = 'addressation',
  attachment = 'attachment',
  tutorial = 'tutorial',
}

/** function to get a Json Mail(e-mail stored as json file)
 * Important: Never use any kind of user defined content here
 *  else might cause XSS
 **/
export async function getJsonMail(path: string) : Promise<JsonMail> {
  const jsonMail : JsonMail = await getJSON(path);
  jsonMail.date = new Date(jsonMail.date);
  jsonMail.from = jsonMail.from.replace(/"/g, '');
  return jsonMail;
}
