import React from 'react';
import './HomeCard.scss';

/** HomeCardProps interface**/
export interface HomeCardProps {
    title: string,
    description: string,
    image: string
}

/**
 * The Security Card View Component
 */
class HomeCard extends React.Component<HomeCardProps> {
  /** Constructor using CardProps **/
  constructor(props: HomeCardProps) {
    super(props);
  }

  /** Render the card view **/
  render() {
    return (
      <div className='HomeCard'
        style={{backgroundImage: `url(${this.props.image})`}}>
        <div className='HomeCardContent'>
          <h2 className='HomeCardTitle'>
            {this.props.title}
          </h2>
          <p className='HomeCardDescription'>
            {this.props.description}
          </p>
        </div>
      </div>
    );
  }
}

export default HomeCard;
