import React from 'react';
import GameState from '../../logic/GameState';
import {observer} from 'mobx-react';
import './ThreatOMat.scss';
import BallsBackground from 'src/assets/ThreatAttack/Balls_Background.svg';
import ThreatOMatImg from 'src/assets/ThreatAttack/Threatomat.svg';
import Ball from 'src/assets/ThreatAttack/Ball.svg';
import BallTop from 'src/assets/ThreatAttack/Ball_Kuppel.svg';
import BallBottom from 'src/assets/ThreatAttack/Ball_Bottom.svg';
import HidingBlock from 'src/assets/ThreatAttack/Ball_Hiding_Block.svg';

interface ThreatOMatProps {
  gameState: GameState
}

interface ThreatOMatState {
  animation: 'initial' | 'dropped' | 'open'
}


/** class to display ThreatOMat**/
@observer
class ThreatOMat extends React.Component<ThreatOMatProps, ThreatOMatState> {
  /** default constructor */
  constructor(props: ThreatOMatProps) {
    super(props);
    this.state = {
      animation: 'initial',
    };
  }

  renderBalls = () => {
    const balls = [];
    let amount = this.props.gameState.threats.stagedThreats.length;
    amount = amount > 20 ? 20 : amount;
    for (let i = amount; i > 0; i--) {
      balls.push(<img key={i} id={`ball${i}`} className='Ball' src={Ball} />);
    }
    return balls;
  }

  /**
   * drops ball on button click
   * reduce score by threat price
   */
  dropBall = () => {
    this.props.gameState.payThreat();
    this.setState({animation: 'dropped'});
    if (this.props.gameState.tutorialStep == 2) {
      this.props.gameState.tutorialStep += 1;
    }
  }
  openBall = () => {
    this.setState({animation: 'open'});
  }

  /**
   * runs on animation end
   * triggers open animation after drop animation
   * triggers reset animation and adds threat after open animation
   */
  getThreatAndReset = () => {
    if (this.state.animation == 'dropped') {
      this.setState({animation: 'open'});
    }
    if (this.state.animation == 'open') {
      this.props.gameState.generateThreat();
      this.setState({animation: 'initial'});
    }
  }
  /** Render function */
  render() {
    return (
      <>
        <div className='ThreatOMat'>
          <img className='Background' src={BallsBackground} />
          <div className='Balls'>
            {this.renderBalls()}
          </div>
          <div className={`SelectedBall ${this.state.animation}`}
            onAnimationEnd={this.getThreatAndReset}
            onClick={this.openBall}>
            <img
              className='BallTop'
              src={BallTop}/>
            <img
              className='BallBottom'
              src={BallBottom}/>
          </div>
          <img className='FrontPlate' src={ThreatOMatImg} />
          <img className='HidingBlock' src={HidingBlock} />
          <button
            disabled={this.props.gameState.threats.stagedThreats.length == 0}
            className={this.props.gameState.tutorialStep == 2?
              `GenerateKnowledgeBTN GenerateKnowledgeBTNGlow`:
              'GenerateKnowledgeBTN'}
            onClick={this.dropBall}
            data-arrow={this.props.gameState.tutorialStep == 2?
              '→': ''}>
            SUCHE BEDROHUNGEN
          </button>
          <div className='Scores'>
            <p>Punkte</p> <p>{this.props.gameState.points}</p>
            <p>Threats</p>
            <p>{this.props.gameState.threats.stagedThreats.length}</p>
          </div>
        </div>
      </>
    );
  }
}

export default ThreatOMat;
