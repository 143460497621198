import React from 'react';
import {observer} from 'mobx-react';

import './PhishingQuiz.scss';
import PhishingQuizExplainPage from
  './PhishingQuizExplainPage/PhishingQuizExplainPage';
import FinView from 'src/utils/FinView/FinView';
import FinishOverview from './FinishOverview/FinishOverview';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GameState from './logic/GameState';
import {GamePages} from './logic/GameState';
import getLevels from './logic/Levels';
import MailSet from './logic/MailSet';
import HintView from './HintView/HintView';
import HintWarn from './HintView/HintWarn';
import FeedbackWidget from 'src/utils/FeedbackWidget/FeedbackWidget';
import HeaderView from './HeaderView/HeaderView';
import PersonalisationMask from './PersonalisationMask/PersonalisationMask';
import TopBar from './TopBar/TopBar';
import QuizBody from './QuizBody/QuizBody';

/**
 * The PhishingQuiz Main Component
 */
@observer
class PhishingQuiz extends React.Component<{}> {
  gameState: GameState;

  /** constructor initializes GameState and calls getLevels function */
  constructor() {
    super({});
    this.gameState = new GameState();
  }

  /** Init state on mount async **/
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
    let shortVersion = false;
    if (document.location.pathname.toLowerCase() == '/phishingquiz/short') {
      shortVersion = true;
    }
    getLevels(shortVersion).then((levels: MailSet[]) => {
      this.gameState.levels = levels;
      this.gameState.init();
    });
  }

  /** unbind event listener **/
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  /** handle key press **/
  handleKeyPress = (e: KeyboardEvent) => {
    if (e.key == 'k' && !this.gameState.activeFeedback &&
      this.gameState.currentMailSet?.activeMail) {
      this.gameState.submit(false);
    } else if (e.key == 'j' && !this.gameState.activeFeedback &&
      this.gameState.currentMailSet?.activeMail) {
      this.gameState.submit(true);
    }
  }

  renderBody = (): JSX.Element => {
    switch (this.gameState.page) {
      case GamePages.loading:
        return <div><h1>Loading ...</h1></div>;

      case GamePages.explain:
        return <PhishingQuizExplainPage clickEvent={
          () => this.gameState.openPersonalisationMask()
        } />;

      case GamePages.finish:
        const finishOverview = (
          <div>
            <FeedbackWidget link={'/PhishingQuiz/AdditionalInfo'}
              position={[40, 100]}
              text={'Weitere Infos'}
            />
            <FinishOverview gameHistory={
              this.gameState.gameHistory}/>
          </div>
        );
        return (
          <FinView score={this.gameState.score}
            maxScore={this.gameState.gameHistory.maxPoints}
            customContent={finishOverview}/>
        );

      case GamePages.personalisation:
        return <PersonalisationMask gameState={this.gameState}/>;

      case GamePages.game:
        return <QuizBody gameState={this.gameState}/>;
    }
  }

  /** Render function **/
  render() {
    return (
      <div className="PhishingQuiz">
        <HintView gameState={this.gameState}/>
        {
          this.gameState.hintWarningVisible?
          <HintWarn
            acceptFunction={()=> {
              this.gameState.toggleHintWarn();
              this.gameState.toggleHint();
            }}
            rejectFunction={()=> this.gameState.toggleHintWarn()}
            costsPerHint={this.gameState.currentMailSet?.POINTS.HINT_COSTS}
          /> : ''
        }
        <HeaderView
          mail={this.gameState.mailHeaderVisibleFor}
          close={() => this.gameState.closeMailHeader()}/>
        <TopBar gameState={this.gameState}/>
        <div className="GameBody">
          {this.renderBody()}
        </div>
        <ToastContainer autoClose={1500}/>
        <div className="GameFooter">
          <a className="FooterLink" href="https://www.bakgame.de/spiele/">
      Zurück zur Spieleauswahl</a>
          <br/>
          <a className="FooterLink" href="https://www.bakgame.de/impressum/">
      Impressum</a>
          <br/>
          <a className="FooterLink" href="https://www.bakgame.de/datenschutz/">
            Datenschutz</a>
        </div>
      </div>
    );
  }
}

export default PhishingQuiz;
