import React from 'react';
import ListView from 'src/utils/ListView/ListView';
import Challenge from 'src/components/ThreatAttack/logic/Challenge';
import GameState from 'src/components/ThreatAttack/logic/GameState';
import './ChallengesListView.scss';
import {observer} from 'mobx-react';


interface ChallengeListViewProps {
  gameState: GameState;
}

/**
 * A Class to view the List of challenges as clickable elements
 */
@observer
class ChallengeListView extends React.Component<ChallengeListViewProps> {
  listReference: React.RefObject<ListView<Challenge>>;

  /** default Constructor */
  constructor(props: ChallengeListViewProps) {
    super(props);
    this.listReference = React.createRef();
  }

  /** create ChallengeListItem from Challenge class */
  createChallengeListItem = (challenge: Challenge) => {
    if (challenge.available) {
      return this.createAvailableChallenge(challenge);
    } else {
      return this.createUnavailableChallenge(challenge);
    }
  }
  /** render an available Challenge */
  createAvailableChallenge = (challenge: Challenge) => {
    return (
      <div
        className='Challenge'
        data-arrow={this.props.gameState.tutorialStep == 3?
          '←': ''}>
        {this.props.gameState.tutorialStep == 3?
          <div
            className='ChallengeGlow'>
            <span className="ChallengeTitle">
              {challenge.title}
            </span>
            <span className="LevelLabel">
              {'Level: ' + challenge.level}
            </span>
          </div>:
          <div>
            <span className="ChallengeTitle">
              {challenge.title}
            </span>
            <span className="LevelLabel">
              {'Level: ' + challenge.level}
            </span>
          </div>
        }
      </div>
    );
  }
  /** render an unavailable Challenge */
  createUnavailableChallenge = (challenge: Challenge) => {
    return (
      <div className="Challenge Unavailable" onClick={(e)=>e.stopPropagation()}>
        <div className="ChallengeTitle">
          {challenge.title}
        </div>
        <div className="LevelLabel">
          {'Level: ' + challenge.level}
        </div>
      </div>
    );
  }

  deselect = () =>{
    this.listReference.current?.deselect();
  }

  restore = () =>{
    this.listReference.current?.restore();
  }

  handeChallengeClick = (chal: Challenge) => {
    this.props.gameState.showChallenge(chal);
    if (this.props.gameState.tutorialStep == 3) {
      this.props.gameState.tutorialStep += 1;
    }
  }

  /** Getter if an item can be restored **/
  get restoreable() {
    return this.listReference.current?.restoreable;
  }


  /** Render the List **/
  render() {
    if (this.props.gameState.challenges !== undefined) {
      return (
        <div>
          <ListView<Challenge>
            ref={this.listReference}
            onClick={this.handeChallengeClick}
            renderEntry={(challenge: Challenge) =>
              this.createChallengeListItem(challenge)}
            list={Array.from(this.props.gameState.challenges.values())}
            selectFirst={this.props.gameState.tutorialStep > 0}
          />
        </div>
      );
    }
  }
}

export default ChallengeListView;
