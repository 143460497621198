import React from 'react';
import BAKManLogo from 'src/assets/logo.svg';
import './TopBar.scss';

/**
 * Class for the TopBar bar, displaying
 * left side elements.
 */
class TopBar extends React.Component {
  /** Render the StatView and its elements **/
  render() {
    return (
      <div className='TopBar'>
        <a href="https://bakgame.de/">
          <img className="Logo" alt="BAK Logo" src={BAKManLogo}
            width="28" height="28"></img>
        </a>
        <span className="GameName">BAKGame</span>
      </div>
    );
  }
}

export default TopBar;
