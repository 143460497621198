import React from 'react';
import './ExplainPage.scss';


interface ExplainPageProps {
  title: string,
  children: React.ReactNode,
  clickEvent: () => void,
}

/**
 * A Class to view a Explain Page,
 * This should be displayed before the game
 * starts or on a help button.
 */
class ExplainPage extends React.Component<ExplainPageProps> {
  /** Constructor with props as parameters:
   *  * title: the title of the game
   *  * text: And jsx element containing the explain text
   *  * ClickEvent: A callback which is triggered on ok.
  **/
  constructor(props: ExplainPageProps) {
    super(props);
  }

  /** Render The score **/
  render() {
    return (
      <div className="ExplainPage">
        <div className="ExplainWrapper">
          <h1 className="ExplainTitle">{this.props.title}</h1>
          <div className="ExplainBody">
            {this.props.children}
          </div>
          <button
            className="ExplainPageButtonOK DesignBTN confirmBTN"
            onClick={this.props.clickEvent}>OK!</button>
        </div>
      </div>
    );
  }
}

export default ExplainPage;
