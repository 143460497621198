import {observer} from 'mobx-react';
import React from 'react';
import {MailIssues} from 'src/api/JsonMailApi';
import GameState from 'src/components/PhishingQuiz/logic/GameState';
import {getIssueText} from
  'src/components/PhishingQuiz/logic/InstantFeedbackText';
import './MailBodyView.scss';

interface MailBodyViewProps {
  gameState: GameState,
}

/**
 * PhishingQuiz MailView Component
 *
 * This displays an MailView using
 * a json, which contains email header
 * fields and a html body.
 */
@observer
class MailBodyView extends React.Component<MailBodyViewProps> {
  feedback: Map<string, string>;

  /** Default constructor with Props:
   * Do not hand over user defined string.
   * This would lead to XSS!
   **/
  constructor(props: MailBodyViewProps) {
    super(props);
    if (props.gameState.currentMail == undefined) {
      throw new Error('current mail is undefined');
    }
    this.feedback = getIssueText(props.gameState.currentMail);
  }

  /** Returns span parent or body */
  findSpanParent(htmlElement: HTMLElement) : HTMLElement {
    while (htmlElement.parentElement) {
      if (htmlElement.nodeName !== 'SPAN') {
        htmlElement = htmlElement.parentElement;
      } else {
        return htmlElement;
      }
    }
    return htmlElement;
  }

  /** Redirect links */
  phishingLinkClickEvent = (event: Event) : boolean => {
    if (event) {
      const isPhishingLink = this.findSpanParent(event.target as HTMLElement).
          className.indexOf('Err') !== -1;

      if (isPhishingLink) {
        this.props.gameState.phishingLinkClicked();
        window.open('/PhishingQuiz/PhishingPage', '_blank');
      } else {
        window.open('/PhishingQuiz/CompanyPage', '_blank');
      }
    }
    // blocks event propagation to the link when used with onclick
    return false;
  }


  /** Execute function when component is first rendered */
  componentDidMount() {
    this.changeLinkOnClick();
  }

  /** Execute function everytime component is updated */
  componentDidUpdate() {
    this.changeLinkOnClick();
  }

  /** show errors if there are corresponding classes */
  showBody = () => {
    if (this.props.gameState.currentMail == undefined) {
      throw new Error('current mail is undefined');
    }
    const bodyAsElement = this.props.gameState.currentMail.getEmailBodyAsDOM();

    // User Defined Content (firstName, lastName and addressation is inserted)
    // Therefore it is important to insert this as text content only but
    // not as HTML to prevent possible XSS!
    const firstNameElements = bodyAsElement.getElementsByClassName(
        'RplcFirstName');
    Array.from(firstNameElements).forEach((el: Element) => {
      el.textContent = this.props.gameState.firstName;
    });
    const lastNameElements = bodyAsElement.getElementsByClassName(
        'RplcLastName');
    Array.from(lastNameElements).forEach((el: Element) => {
      el.textContent = this.props.gameState.lastName;
    });
    const addressationElements = bodyAsElement.getElementsByClassName(
        'RplcAddressation');
    Array.from(addressationElements).forEach((el: Element) => {
      el.textContent = this.props.gameState.addressation;
    });

    if (this.props.gameState.activeFeedback) {
      for (const errorType in MailIssues) {
        if (typeof errorType === 'string') {
          const errorElements = bodyAsElement.getElementsByClassName(
              'Err'+errorType);
          Array.from(errorElements).forEach((el: Element) => {
            let dataText = this.feedback.get(errorType);
            if (!dataText) {
              dataText = '';
            }
            el.setAttribute('data-text', dataText);
            if (this.props.gameState.currentMail?.isPhishing) {
              el.classList.add('ErrorTooltip--phishing');
            } else {
              el.classList.add('ErrorTooltip--regular');
            }
          });
        }
      }
    }
    return bodyAsElement.innerHTML;
  }

  /** Set onclick function for links in mails */
  changeLinkOnClick() {
    const hyperlinkElements = document.getElementsByTagName('a');
    Array.from(hyperlinkElements).forEach((linkElement) => {
      if (linkElement.className.indexOf('MailbodyLink') !== -1) {
        linkElement.onclick = this.phishingLinkClickEvent;
      }
    });
  }

  /** Render HTML in Dangerous mode **/
  render() {
    return (
      <div className="MailBodyView"
        dangerouslySetInnerHTML={{__html: this.showBody()}}/>
    );
  }
}

export default MailBodyView;
