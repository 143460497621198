import React from 'react';
import GameState from '../logic/GameState';
import ThreatList from '../ThreatList/ThreatList';
import ThreatOMat from './ThreatOMat/ThreatOMat';
import {observer} from 'mobx-react';


interface ThreatOverviewProps {
  gameState: GameState
}

/** Overview class for all threats and threatomate**/
@observer
class ThreatOverview extends React.Component<ThreatOverviewProps> {
  /** Render function**/
  render() {
    return (
      <div className='ThreatContainer rightSideView'>
        <ThreatOMat gameState={this.props.gameState}/>
        <ThreatList gameState={this.props.gameState} screen={'overview'}/>
      </div>
    );
  }
}

export default ThreatOverview;
