import React from 'react';
import './CompanyPage.scss';
import trustLink from 'src/assets/PhishingQuiz/trustedLink.svg';

/** Phishing landing Page**/
class CompanyPage extends React.Component {
  /** Simple render function **/
  render() {
    return (
      <div className="CompanyPage">
        <div className="content">
          <img src={trustLink} alt="Ausrufe Zeichen"/>
          <h1>Dies ist eine vertrauenswürdige Seite</h1>
          <div>
            <h2>
              Woran haben Sie erkannt, dass es sich bei dem Link
              um einen legitimen Link gehandelt hat?
            </h2>
            <h3>
              Klicken Sie nur auf Links, wenn sie sich sicher sind,
              dass diese legitim sind.
            </h3>
            <h3>
              Legitime Links erkennen Sie an folgenden Merkmalen:
            </h3>
            <ul>
              <li>
                Die letzte Link-Endung (bspw. .de, .com) vor dem
                ersten &bdquo;/&ldquo; zählt. Angreifer versuchen oftmals
                diese zu maskieren, wie beispielsweise
                google.com.evilcorp.xyz/.
              </li>
              <li>
                Links können maskiert werden und auf eine andere
                Seite als in der E-Mail steht weiterleiten. Ihr Browser
                zeigt Ihnen unten links die Ziel-Adresse eines Links an,
                wenn Sie mit der Maus über den Link fahren.
              </li>
              <li>
                Falls Sie eigentlich bekannte Seiten besuchen, achten
                Sie besonders auf Rechtschreibfehler,
                fehlende Bilder und unübliches verändertes Aussehen der
                Seite.
              </li>
              <li>
                Weitere Hinweise auf Phishing in der Mail selbst. Überprüfen
                Sie bei Links nochmals die E-Mail selbst auf weitere Phishing
                Hinweise.
              </li>
            </ul>
          </div>
          <button className='CloseWindowBTN' onClick={ () => {
            window.close();
          }}>Schließen</button>
        </div>
      </div>
    );
  }
}

export default CompanyPage;
