import React from 'react';
import './HintView.scss';

interface HintWarnProps {
  acceptFunction: ()=> void;
  rejectFunction: ()=> void;
  costsPerHint: number | undefined,
}

/** Warn that hintview will be shown **/
class HintWarn extends React.Component<HintWarnProps> {
  /** Default Constructor **/
  constructor(props: HintWarnProps) {
    super(props);
  }

  /** Default render **/
  render() {
    return (
      <div className="HintWarn HintWrapper">
        <div className="HintContent">
          <h2>Tipps anzeigen?</h2>
          <p>
            Durch das Anzeigen der Tipps werden
            Ihnen {Math.abs(this.props.costsPerHint?
            this.props.costsPerHint:0)} Punkte abgezogen.
          </p>
          <div>
            <button className="DesignBTN confirmBTN"
              onClick={() => this.props.acceptFunction()}>
            Tipps Anzeigen
            </button>
            <button className="DesignBTN cancelBTN"
              onClick={() => this.props.rejectFunction()}>
            Abbrechen
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default HintWarn;
