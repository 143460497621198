import React from 'react';

import './ThreatAttackExplainPage.scss';
import ExplainPage from 'src/utils/ExplainPage/ExplainPage';
import IntroGif from 'src/assets/ThreatAttack/ThreatAttackIntro.gif';

export interface ThreatAttackExplainPageProps {
  clickEvent: () => void,
}

/** The Explain Page, which can be displayed
 * just before the Quiz starts
 **/
class ThreatAttackExplainPage extends
  React.Component<ThreatAttackExplainPageProps> {
  /** Constructor taking a onClick function for the button**/
  constructor(props: ThreatAttackExplainPageProps) {
    super(props);
  }

  /** Render The Explain text **/
  render() {
    return (
      <ExplainPage
        title="ThreatAttack Anleitung"
        clickEvent={this.props.clickEvent}>
        <div className="ExplainText">
          <div className='IntroGif'>
            <img src={IntroGif} alt='Intro' height={405} width={850} />
          </div>
          <p>
            Bei diesem Spiel schlüpfen Sie in die Rolle eines Angreifers.
          Sie sollen verschiedene <b>Challenges</b> lösen. Diese
          werden in einer Liste auf der linken Seite angezeigt und
          Stück für Stück freigeschalten.
          </p>

          <p>
            Um eine <b>Challenge</b> zu lösen, müssen Sie dieser eine
          Anzahl an <b>Threats</b> (Bedrohungen) zuordnen.
          Das Level der <b>Challenge</b> gibt die Anzahl an Threats an,
          die benötigt wird, um die <b>Challenge</b> zu lösen.
          </p>

          <p id="threats" >
      Ein <b>Threat</b>, beziehungsweise eine Bedrohung, beschreibt eine
      konkrete oder abstrakte Gefahr für ein System, z.B.
      Unbefugte können Zutritt zum Gebäude erlangen oder
      Unbefugte könnten ein Erdgeschoss Fenster
      einschlagen und durch dieses Zutritt zum Gebäude erlangen.
      Des Weiteren existieren Threats prinzipiell unabhängig davon,
      ob gegen diese eine Maßnahme ergriffen
      wurde oder nicht. Allerdings können Threats, gegen welche
      entsprechende Vorkehrungen getroffen wurden, nicht mehr durch
      einen Angreifer ausgenutzt werden.
          </p>

          <p>
            Auf der rechten Seite sehen Sie zu Spielbeginn
          eine Übersicht aller vorhandenen <b>Threats</b> sowie
          den <b>Threat-O-Mat</b>, welcher <b>Punkte</b> verbraucht
          und Ihnen neue <b>Threats</b> generieren kann.
          </p>

          <p>
            Wenn Sie eine <b>Challenge</b> ausgewählt haben, können
          Sie die bereits erzeugten <b>Threats</b> der <b>Challenge</b> zuordnen
          und damit versuchen diese zu lösen.
          Wenn Sie eine <b>Challenge</b> korrekt lösen, schalten Sie eine neue
          frei und erhalten <b>Punkte</b>.
          </p>

          <p>
            Es lohnt sich mit den <b>Punkten</b> sparsam umzugehen,
            denn diese bestimmen, wie erfolgreich Sie am Ende des Spiels waren,
            wenn alle <b>Challenges</b> gelöst wurden.
          </p>

        </div>
      </ExplainPage>
    );
  }
}

export default ThreatAttackExplainPage;
