import React from 'react';
import './CardDetailScreen.scss';
import Skill from '../logic/cards/Skill';
import CardView from '../CardView/CardView';
import Event from '../logic/cards/Event';
import Overlay from 'src/utils/Overlay/Overlay';

interface CardDetailScreenProps {
  useFunction: (skill: Skill) => void | undefined,
  activeEvent: Event|undefined,
}

interface CardDetailScreenState {
  visible: boolean,
  skill: Skill|undefined,
  activatable: boolean,
}

/** Display the Card in fullscreen and ask
 * if it should be used **/
class CardDetailScreen extends React.Component<CardDetailScreenProps,
  CardDetailScreenState> {
  /** default constructor **/
  constructor(props: CardDetailScreenProps) {
    super(props);
    this.state = {
      visible: false,
      skill: undefined,
      activatable: false,
    };
  }

  /** Hide the overlay **/
  hide = () => {
    this.setState({
      visible: false,
    });
  }

  /** Show skill in the overlay **/
  show(selectedSkill: Skill) {
    let activatable: boolean;

    if (this.props.activeEvent) {
      activatable = selectedSkill.checkFor(this.props.activeEvent);
    } else {
      throw Error('activeEvent is not defined!');
    }

    this.setState({
      visible: true,
      skill: selectedSkill,
      activatable: activatable,
    });
  }

  applyCard = () => {
    if (this.state.skill && this.props.useFunction) {
      this.props.useFunction(this.state.skill);
      this.hide();
    }
  }

  /** Render the screen overlay **/
  render() {
    let card: JSX.Element;
    if (this.state.skill) {
      card = (
        <CardView skill={this.state.skill}
          isMinified={false}
          onClickFunction={undefined}
          usable={true}/>
      );
    } else {
      card = (
        <div></div>
      );
    }
    return (
      <Overlay visible={this.state.visible}>
        <div className="TextField">
          {card}
          <span>
            Die Karte ausspielen?
          </span>
          <div>
            <button className="DesignBTN confirmBTN"
              onClick={this.applyCard} disabled={!this.state.activatable}>
              Ausspielen</button>
            <button className="DesignBTN cancelBTN"
              onClick={this.hide}>Abbrechen</button>
          </div>
        </div>
      </Overlay>
    );
  }
}

export default CardDetailScreen;
