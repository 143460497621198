import React from 'react';
import './LevelView.scss';

interface LevelViewProps {
    currentLevelNumber: number,
    maxLevels: number,
    title: string,
}

/** simple component to display level number and max amound of levels */
class LevelView extends React.Component<LevelViewProps> {
  /** default constuctor */
  constructor(props:LevelViewProps) {
    super(props);
  }

  /** Number View **/
  getNumberView = (): string => {
    if (this.props.currentLevelNumber > 0) {
      return `Level ${this.props.currentLevelNumber+' '}von
      ${' '+this.props.maxLevels}`;
    }
    return '';
  }

  /** default render Function */
  render() {
    return (<span className="GameStatus LevelView">
      {this.getNumberView()}
      {this.getNumberView() && this.props.title?': ':''}
      {this.props.title}
    </span>);
  }
}
export default LevelView;
