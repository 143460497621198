import {IChallenge} from 'src/api/ThreatAttack/ChallengeApi';


/** class for challenges in ThreatAttack*/
class Challenge implements IChallenge {
  id: string;
  title: string;
  description: string;
  level: number;
  threatIds: string[];
  available: boolean;
  usedThreats: string[];

  /** Constructor creating a challenge. */
  constructor(template: IChallenge) {
    this.id = template.id;
    this.title = template.title;
    this.description = template.description;
    this.level = template.level;
    this.threatIds = template.threatIds;
    this.available = false;
    this.usedThreats = [];
  }

  /** Check if certain challenge matches the threat.
   * One challenge can match to several threats but
   * one threat matches only one challenge.
   */
  doesThreatMatch(threatId: string) : boolean {
    return this.threatIds.includes(threatId);
  }

  /** Use threats (this is required for unstaging threats logic) **/
  useThreats(threatIds: string[]) {
    this.usedThreats = this.usedThreats.concat(threatIds);
  }
}
export default Challenge;
