import React from 'react';
import GameState from '../logic/GameState';
import './ThreatList.scss';
import ListView from 'src/utils/ListView/ListView';
import Threat from '../logic/Threat';
import {observer} from 'mobx-react';
import {action, makeObservable} from 'mobx';

interface ThreatListProps {
  gameState: GameState;
  screen: string;
}

/**
 * Displays a List of Threats
 */
@observer
class ThreatList extends React.Component<ThreatListProps> {
  /** Constructor */
  constructor(props: ThreatListProps) {
    super(props);
  }

  /** default render */
  render() {
    return (
      <div className='ThreatList'>
        <ul className='ThreatListView'>
          {this.props.gameState.threats.availableThreats.map(
              (threat, index) => {
                return (<li key={'Threat' + index}
                  className='Threat'>{threat.name}</li>);
              },
          )}
        </ul>
      </div>
    );
  }
}

export default ThreatList;
