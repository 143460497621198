import React from 'react';
import './EventView.scss';
import {observer} from 'mobx-react';
import HintWidget from 'src/utils/HintWidget/HintWidget';
import Event from 'src/components/SecurityCards/logic/cards/Event';

interface EventViewProps {
  event: Event,
  showEventDescription: boolean,
}


/** Class to view event Text **/
@observer
class EventView extends React.Component<EventViewProps> {
  /** Default constructor with props **/
  constructor(props: EventViewProps) {
    super(props);
  }

  /** Render functionality **/
  render() {
    let occurDescription: string;
    let occurClass: 'good' | 'bad' | 'empty';
    occurClass='empty';
    const harmlessEvent = this.props.event.category == 'harmless';

    if (this.props.showEventDescription) {
      occurDescription = '';
    } else if (this.props.event.occurs) {
      occurDescription = this.props.event.occurText;
      occurClass = harmlessEvent? 'good': 'bad';
    } else {
      occurDescription = this.props.event.notOccurText;
      occurClass = harmlessEvent? 'bad': 'good';
    }

    return (
      <div className="EventView">
        <div className='TextFrame'>
          <h2>{this.props.event.label}</h2>
          <p className="Description">
            {this.props.event.description}</p>
          <p className={`occurText ${occurClass}`}>
            {occurDescription}
          </p>
          {(this.props.event.hintText) ?
            (<HintWidget
              hintText={this.props.event.hintText} />) :
            undefined
          }
        </div>
      </div>
    );
  }
}

export default EventView;
