import {action, makeObservable, observable, runInAction, computed} from 'mobx';
import Challenge from '../logic/Challenge';
import Threat from './Threat';
import ThreatPools from './ThreatPools';
import {getChallenge} from 'src/api/ThreatAttack/ChallengeApi';
import {toast} from 'react-toastify';
import HackState from './HackState';

const CHALLENGE_FILES = ['tutorialChallenge1.json',
  'phishing.json', 'windows7pc.json',
  'passwordTheft.json', 'ransomware.json', 'badusb.json', 'ddos.json',
  'productionStop.json', 'botnet.json', 'dataTheft.json'];
const THREAT_FILES = ['tutorialThreat1.json', 'fakeEmailSender.json',
  'oldOs.json', 'dataBreach.json', 'lackOfTraining.json',
  'oldSoftware.json', 'thirdPartyHack.json', 'maskedAttacker.json',
  'wrongUserConfig.json', 'informationDisclosure.json',
  'networkAccessable.json',
  'suspiciousHardware.json', 'fakeAttachment.json', 'fakeLinks.json',
  'fakePage.json', 'overTheShoulder.json', 'physicalAttacker.json',
  'missingRateLimit.json', 'unprotectedUSBports.json', 'missingCaptcha.json',
  'missingRateLimit.json', 'plaintextPasswords.json',
  'missingSegmentation.json', 'unlockedComputers.json',
  'defaultPasswords.json'];

const CHALLENGE_FILES_SHORT = ['tutorialChallenge1.json',
  'phishing.json', 'windows7pc.json', 'ransomware.json',
  'productionStop.json', 'dataTheft.json'];


export enum screens {
  explainpage,
  overview,
  challenge,
  fin,
  loading,
}

const START_CHALLENGES = [
  'phishing',
  'windows7pc',
];

const TUTORIAL_CHALLENGES = [
  'tutorialChallenge1',
];


export const HACKPOWER_PER_THREAT = 5;

/** Game State for ThreatAttack **/
export default class GameState {
  challenges: Map<string, Challenge> = new Map();
  preloadChallenges: Map<string, Challenge> = new Map();
  preloadTutorialChallanges: Map<string, Challenge> = new Map();
  threats: ThreatPools = new ThreatPools();
  hackState = new HackState();
  initialized = false;
  activeScreen = screens.loading;
  clickedChallenge: Challenge | undefined;
  selectedThreats: Threat[] = [];
  dragedThreat: Threat | undefined;
  points = 0;
  lock = false;
  solvedChallenges = 0;
  tutorialStep = 0;
  nextThreatPayed = false;

  /** Constructor**/
  constructor() {
    makeObservable(this, {
      activeScreen: observable,
      challenges: observable,
      threats: observable,
      initialized: observable,
      clickedChallenge: observable,
      selectedThreats: observable,
      hackState: observable,
      points: observable,
      solvedChallenges: observable,
      tutorialStep: observable,
      loadChallenges: action,
      load: action,
      init: action,
      showChallenge: action,
      showOverview: action,
      showTutorialPage: action,
      makeChallengeAvailable: action,
      continueOrFinish: action,
      generateThreat: action,
      removeUsedThreats: action,
      toggleThreat: action,
      payThreat: action,
      hack: action,
      endTutorial: action,
    });
  }

  /** set the challenges via API call **/
  async loadChallenges(fileNames: string[]) {
    for (let i = 0; i < fileNames.length; i++) {
      const iChallenge = await getChallenge(fileNames[i]);
      const challenge = new Challenge(iChallenge);
      if (TUTORIAL_CHALLENGES.includes(challenge.id)) {
        runInAction(() => this.preloadTutorialChallanges.set(
            challenge.id, challenge));
      } else {
        runInAction(() => this.preloadChallenges.set(challenge.id, challenge));
      }
    }
  }

  /** Select / Unselect Threat **/
  toggleThreat(threat: Threat) {
    if (this.selectedThreats.includes(threat)) {
      /** Unselect threat **/
      const index = this.selectedThreats.indexOf(threat);
      if (index != -1) {
        this.selectedThreats.splice(index, 1);
      }
    } else {
      /** Select threat**/
      if (this.clickedChallenge != undefined &&
        this.selectedThreats.length < this.clickedChallenge.level) {
        this.selectedThreats.push(threat);
      }
    }
  }

  /** Pay the point consts for a threat **/
  payThreat() {
    if (!this.nextThreatPayed && this.points >= HACKPOWER_PER_THREAT) {
      this.points -= HACKPOWER_PER_THREAT;
      this.nextThreatPayed = true;
    }
    if (this.tutorialStep >= 0) {
      toast.info('Achtung! Der Threat-O-Mat verbraucht 5 Punkte um eine' +
        ' neue Bedrohung zu suchen. Versuchen Sie also vorhandene' +
        ' Bedrohungen zu nutzen.', {autoClose: 10000});
    }
  }

  /** Generate new threats with the threatmeter button **/
  generateThreat() {
    if (this.nextThreatPayed) {
      this.threats.makeThreatAvailable();
      this.nextThreatPayed = false;
    }
    if (this.tutorialStep >= 0) {
      toast.info(
          `Lesen Sie die Bedrohung durch und gehen Sie dann
          zur Challenge zurück.`,
          {autoClose: 10000});
    }
  }

  /** Async load all data helper function **/
  async loadData(shortVersion: boolean) {
    this.challenges.clear();
    this.threats.allThreats.clear();
    this.threats.availableThreats = [];
    this.threats.stagedThreats = [];
    if (!shortVersion) {
      await this.loadChallenges(CHALLENGE_FILES);
    } else {
      await this.loadChallenges(CHALLENGE_FILES_SHORT);
    }
    await this.threats.load(THREAT_FILES);
  }

  /** Sync load **/
  load(shortVersion: boolean) {
    this.activeScreen = screens.loading;
    this.loadData(shortVersion).then(() => this.init());
  }

  /** Make challenge available **/
  makeChallengeAvailable(challengeID: string) {
    const challenge = this.challenges.get(challengeID);
    if (challenge) {
      challenge.available = true;
      this.threats.stageThreats(challenge.threatIds);
      this.points += challenge.threatIds.length * HACKPOWER_PER_THREAT;
    } else {
      throw Error(`Challenge with ID: ${challengeID} does not exist`);
    }
  }

  /** Switch initialization state **/
  init() {
    this.initialized = true;
    this.activeScreen = screens.explainpage;
  }

  /** Show challenge **/
  showChallenge(challenge?: Challenge) {
    if (!challenge?.available) {
      return;
    } else {
      this.hackState.resetWrongHacks();
      if (this.clickedChallenge !== challenge) {
        this.clickedChallenge = challenge;
        this.activeScreen = screens.challenge;
        this.selectedThreats = [];
      } else {
        this.clickedChallenge = undefined;
        this.showOverview();
      }
    }
  }

  /** Unlock next chalange or fin **/
  continueOrFinish() {
    if (this.tutorialStep == 0) {
      toast.dismiss();
      this.endTutorial();
      return;
    }

    if (this.clickedChallenge == undefined) {
      throw Error('Clicked Challenge is undefined');
    }
    this.challenges.delete(this.clickedChallenge.id);
    for (const value of Array.from(this.challenges.values())) {
      if (!value.available) {
        this.makeChallengeAvailable(value.id);
        break;
      }
    }
    if (this.challenges.size == 0) {
      this.activeScreen = screens.fin;
    } else {
      this.showOverview();
    }
  }

  /** remove used threats **/
  removeUsedThreats(threadIDs: string[]) {
    const removeValues = [];
    for (const thID of threadIDs) {
      if (this.threats.consumeThreat(thID)) {
        removeValues.push(thID);
      }
    }

    this.selectedThreats = [];
  }

  /** handle different scenarios in threat selection
   * @return true when toast is triggered else false
  */
  handleHackToasts(): boolean | undefined {
    if (this.clickedChallenge == undefined) {
      throw Error('Clicked Challenge is undefined');
    }
    if (this.selectedThreats.length < this.clickedChallenge.level) {
      return;
    }

    if (this.selectedThreats.length != this.clickedChallenge.level) {
      return;
    }
    for (const threat of this.selectedThreats) {
      if (this.selectedThreats.filter((t) => t.id == threat.id).length > 1) {
        return true;
      }
    }
    return false;
  }

  /** use threats for current challenge */
  hack() {
    if (this.selectedThreats == undefined) {
      throw Error('Selected Threat is undefined');
    }

    if (this.clickedChallenge == undefined) {
      throw Error('Clicked Challenge is undefined');
    }
    let msg = '';
    if (this.handleHackToasts() == undefined) {
      return;
    } else if (this.handleHackToasts()) {
      msg = 'Jede Bedrohung darf nur einmal verwendet werden.';
      return [msg, 'warn'];
    }
    const wrongThreats: Threat [] = [];
    for (const threat of this.selectedThreats) {
      if (!this.clickedChallenge.doesThreatMatch(threat.id)) {
        wrongThreats.push(threat);
        this.points = this.points > 5 ? this.points - 5 : 0;
        this.hackState.handleFailedHack();
      }
    }
    if (wrongThreats.length > 0) {
      for (let i = 0; i < wrongThreats.length; i++) {
        const index = this.selectedThreats.indexOf(wrongThreats[i]);
        if (index > -1) {
          this.selectedThreats.splice(index, 1);
        }
      }
      msg ='Challenge konnte nicht gelöst werden!\n' +
      'Ein oder mehrere Bedrohungen passen nicht zur Challenge.';
      return [msg, 'error'];
    }
    this.hackState.resetWrongHacks();
    this.points = this.points + this.clickedChallenge.level * 5;
    this.solvedChallenges += 1;
    const usedThreatIDs = this.selectedThreats.map((threat) => {
      return threat.id;
    });
    this.removeUsedThreats(usedThreatIDs);
    this.threats.unstageThreats(this.clickedChallenge.threatIds,
        usedThreatIDs);
    this.continueOrFinish();
    this.clickedChallenge = undefined;
    msg = 'Erfolg! Die Bedrohungen konnten erfolgreich zum ' +
    'Lösen der Challenge verwendet werden.';
    return [msg, 'success'];
  }

  /** Show the overview with threatomat and threats**/
  endGame() {
    this.activeScreen = screens.fin;
  }

  /** End tutorial and start the game**/
  endTutorial() {
    toast.dismiss();
    this.threats.availableThreats = [];
    this.threats.stagedThreats = [];
    this.tutorialStep = -1;
    this.solvedChallenges = 0;
    this.challenges = this.preloadChallenges;
    for (const challange of START_CHALLENGES) {
      this.makeChallengeAvailable(challange);
    }
    this.showOverview();
  }

  /** Show the overview with threatomat and threats**/
  showOverview() {
    this.activeScreen = screens.overview;
    this.selectedThreats = [];
    this.clickedChallenge = undefined;
    if (this.tutorialStep == 1) {
      this.tutorialStep += 1;
    }
  }

  /** Show the tutorial page **/
  showTutorialPage() {
    this.challenges = this.preloadTutorialChallanges;
    this.selectedThreats = [];
    this.tutorialStep += 1;
    this.makeChallengeAvailable(TUTORIAL_CHALLENGES[0]);
    this.clickedChallenge = this.challenges.get(TUTORIAL_CHALLENGES[0]);
    this.activeScreen = screens.challenge;
    toast.info('Sie benötigen Threats um die Challenges zu lösen. Diese'+
        ' erhalten sie vom Threat-O-Mat', {autoClose: 7500});
  }
}

