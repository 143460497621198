import React from 'react';
import './ReadMore.scss';

import KMU from './Images/kmu.svg';
import Info from './Images/Info.svg';
import Help from './Images/Helping.svg';
import Link from './Images/Link.svg';
import Tisim from './Images/cybersicher-logo.svg';


/** ReadMore component to display additional information. **/
class ReadMore extends React.Component {
  /** default render function **/
  render() {
    return (
      <div id='Werbung'>
        <div id='WerbungTop'>
          <img id='WerbungLogo'
            src={Link}/>
          <p className='WerbungText'>
            Mehr zum Thema von anderen Förderungsprogrammen
          </p>
        </div>
        <div id='WerbungMain'>
          <a
            href='https://kmu-einfach-sicher.de/was-sind-eigentlich-phishing-ransomware/'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Info}/>
              <div className='Company'>
                <p className='CompanyText'>
                  KMU. Einfach Sicher.
                </p>
                <img className='CompanyLogo'
                  src={KMU}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Was sind eigentlich: Phishing, Ransomware & Co?
                </span>
                <p className='ContentText'>
                  Ein einfacher Überblick über die gängisten Begriffe
                </p>
              </div>
            </div>
          </a>
          <a
            href='https://weiterbildung.kmu-einfach-sicher.de/signin/e-learning/kmu-einfach-sicher/mail_mitarbeiter_03-01/units/23779/elearning/0/'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Info}/>
              <div className='Company'>
                <p className='CompanyText'>
                  KMU. Einfach Sicher.
                </p>
                <img className='CompanyLogo'
                  src={KMU}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Einfach Sicher - Einloggen
                </span>
                <p className='ContentText'>
                  30-minütiges E-Learning Modul über die
                  Wichtigkeit von sicheren E-Mails. Zeigt,
                  wie man das einfach umsetzen kann, um sich
                  zu schützen.
                </p>
              </div>
            </div>
          </a>
          <a href='https://transferstelle-cybersicherheit.de/material/phishing-mails-kurz-erklart/'>
            <div className='LinkWrapper'>
              <img className='LinkIcon'
                src={Help}/>
              <div className='Company'>
                <p className='CompanyText'>
                  Transferstelle Cybersicherheit
                </p>
                <img className='CompanyLogo'
                  src={Tisim}/>
              </div>
              <div className='LinkContent'>
                <span className='ContentHeadline'>
                  Phishing-E-Mails kurz erklärt
                </span>
                <p className='ContentText'>
                  Informationsvideo zu Phishing-E-Mails.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div id='WerbungBottom'>
          <p className='WerbungText'>
            Verlinkungen verweisen auf externe Inhalte für
            die BAKGame nicht verantwortlich ist.
          </p>
        </div>
      </div>
    );
  }
}

export default ReadMore;
