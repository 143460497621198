import React from 'react';
import {Link} from 'react-router-dom';
import './Home.scss';
import TopBar from './TopBar/TopBar';
import HomeCard from './HomeCard/HomeCard';
import PhishingQuizScr from 'src/assets/Home/Screenshots/PhishingQuiz.png';
import SecurityCardsScr from 'src/assets/Home/Screenshots/SecurityCards.png';
import ThreatAttackScr from 'src/assets/Home/Screenshots/ThreatAttack.png';
import PasswordGameScr from 'src/assets/Home/Screenshots/PasswordGame.png';

/** Home component with link elements*/
function Home() {
  return (
    <div className="Home">
      <TopBar/>
      <div className='HomeFlex'>
        <Link className='HomeCardLink' to={'/PhishingQuiz'}
          id='PhishingQuizLink'>
          <HomeCard title='Phishing-Quiz'
            description='In diesem Spiel werden Sie mit Bedrohungen, welche von
            E-Mails ausgehen können, konfrontiert und lernen, Warnzeichen von
            bösartigen E-Mails zu erkennen.'
            image={PhishingQuizScr}/>
        </Link>
        {/* <a className='HomeCardLink' href='/PasswordGame/'
          id='PasswordGameLink'>
                <HomeCard title='Password-Game'
                  description='In diesem Spiel schlüpfen sie in die Rolle eines
                  Angreifers. Können Sie sich in den unbewachten PC einloggen?'
                  image={PasswordGameScr}/>
              </a>
              */}
        <Link className='HomeCardLink' to={'/SecurityCards'}
          id='SecurityCardsLink'>
          <HomeCard title='Security-Cards'
            description='In SecurityCards spielen Sie einen
            Entscheidungsträger für ein fiktives Unternehmen. Sie
            treffen die Entscheidung in welche IT-Absicherungsmaßnahmen
            Sie investieren wollen.'
            image={SecurityCardsScr}/>
        </Link>
        <Link className='HomeCardLink' to={'/ThreatAttack'}
          id='ThreatAttackLink'>
          <HomeCard title='Threat-Attack'
            description='In diesem Spiel schlüpfen Sie in die Rolle eines
            Angreifers. Schaffen Sie es alle Angriffswege richtig
            zuzuordnen?'
            image={ThreatAttackScr}/>
        </Link>
      </div>
    </div>
  );
}

export default Home;
