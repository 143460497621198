import React from 'react';
import GameState from '../logic/GameState';
import {getExplainTextes} from './hintTextes';
import './HintView.scss';
import {observer} from 'mobx-react';

export interface HintViewProps {
  gameState: GameState,
}

/** Show Hints for the mail **/
@observer
export default class HintView extends React.Component<HintViewProps> {
  /** Constructor working with GameState **/
  constructor(props: HintViewProps) {
    super(props);
  }

  /** render the hints **/
  render() {
    if (!this.props.gameState.currentMailSet) {
      return null;
    }
    if (!this.props.gameState.currentMail) {
      return null;
    }
    if (!this.props.gameState.hintVisible) {
      return null;
    }
    const hints = getExplainTextes(
        this.props.gameState.currentMail);
    const error = hints.length > 0 ?
      '' :
      <p>Für diese E-Mail sind leider keine Tipps vorhanden.</p>;
    return (
      <div className="HintView HintWrapper">
        <div className="HintContent">
          <h2>Tipps:</h2>
          {error}
          <ul>
            {hints.map((hint, index) =>
              <li key={index}>{hint}</li>,
            )}
          </ul>
          <div>
            <button className="DesignBTN confirmBTN"
              onClick={() => this.props.gameState.toggleHint()}>
          OK, Schließen</button>
          </div>
        </div>
      </div>
    );
  }
}
