import React from 'react';
import './ChallengeActivation.scss';
import {observer} from 'mobx-react';
import GameState from '../../logic/GameState';
import Threat from '../../logic/Threat';
import {toast} from 'react-toastify';

interface ChallengeActivationProps {
  gameState: GameState
}

/**
 * A Class to view the List of challenges as clickable elements
 */
@observer
class ChallengeActivation extends React.Component<ChallengeActivationProps> {
  // Variables to handle displaying of warn and error toasts only once
  warnToastDisplayed = false;
  errorToastDisplayed = false;
  /** Default constructor **/
  constructor(props: ChallengeActivationProps) {
    super(props);
  }
  /** display error or warn toasts */
  displayErrorWarnToast = (msg: string []) => {
    switch (msg[1]) {
      case 'warn':
        if (!this.warnToastDisplayed) {
          toast.warn(msg[0], {autoClose: 7500});
          this.warnToastDisplayed = true;
        }
        break;
      case 'error':
        if (!this.errorToastDisplayed) {
          toast.error(msg[0], {autoClose: 7500});
          this.errorToastDisplayed = true;
        }
        break;
    }
  }
  /** handle button click for hacking */
  handleBTNhack = () => {
    if (this.props.gameState.tutorialStep > 0) {
      this.props.gameState.tutorialStep = 0;
    }
    const hackMessage = this.props.gameState.hack();
    if (hackMessage !== undefined) {
      if (hackMessage[1] !== 'success') {
        this.displayErrorWarnToast(hackMessage);
        for (const th of this.props.gameState.selectedThreats) {
          this.handleThreatSelectionColor(th, true);
        }
      } else {
        toast.success(hackMessage[0], {autoClose: 5000});
      }
    }
  }
  /** handle coloring of selected threats */
  handleThreatSelectionColor = (threat: Threat, hack: boolean) => {
    const idx = this.props.gameState.threats.availableThreats.indexOf(threat);
    const threatLabel = document.getElementById('ThreatLabel' + idx);
    if (threatLabel !== null) {
      threatLabel.className = `CheckboxLabel${hack?' correctSelection':''}`;
    }
  }

  /** Toggle Threat-Checkbox if clicked */
  toggleCheckbox = (threat: Threat) => {
    this.props.gameState.toggleThreat(threat);
    if (this.props.gameState.tutorialStep > 0) {
      this.props.gameState.tutorialStep += 1;
    }
    this.handleThreatSelectionColor(threat, false);
  }

  /** Returns the Level of the Challenge */
  getLevel() {
    return this.props.gameState.clickedChallenge?
      this.props.gameState.clickedChallenge.level:0;
  }

  /** Check if Checkbox should be selected or not */
  getCheckboxStatus = (threat: Threat) => {
    return this.props.gameState.selectedThreats.includes(threat);
  }

  /** render function */
  render() {
    return (
      <div className="ChallengeActivation">
        <h3>Ich nutze folgende Bedrohungen
          ({this.props.gameState.selectedThreats.length} von {this.getLevel()}
        {' '} ausgewählt):</h3>
        <div>
          {this.props.gameState.threats.availableThreats.map(
              (threat, index) => {
                return (
                  <div key={'Threat' + index} className="Checkbox">
                    <input type="checkbox" className="CheckboxSquare"
                      onChange={() => this.toggleCheckbox(threat)}
                      checked={this.getCheckboxStatus(threat)}
                      id={'Threat' + index}
                      name={'Threat' + index}/>
                    <label id={'ThreatLabel' + index} htmlFor={'Threat' + index}
                      className={this.props.gameState.tutorialStep == 4?
                        'CheckboxLabel ThreatGlow': 'CheckboxLabel'}>
                      {threat.name}</label><br></br>
                  </div>
                );
              })}
        </div>
        <button onClick={this.handleBTNhack}
          className="ChallengeSupplyBTN">ANGRIFF STARTEN</button>
      </div>
    );
  }
}

export default ChallengeActivation;
