import React from 'react';
import './EffectViewContainer.scss';

import Effect from '../logic/effects/Effect';
import EffectView from './EffectView';

interface EffectViewContainerProps{
    effects: Effect[],
}

/** container class to display all active effects in effectviews */
export default class EffectViewContainer extends
  React.Component<EffectViewContainerProps> {
  /** render function */
  render() {
    return (
      <div className="EffectViewContainer">
        {this.props.effects.map((effect)=>(<EffectView
          key={effect.name} effect={effect} />))}
      </div>
    );
  }
}
