import Effect from './Effect';
import {IBlockEffect} from 'src/api/SecurityCards/EffectsApi';

/** Class containing effects, blocking one or several cards **/
class BlockEffect extends Effect implements IBlockEffect {
  blockReason: string;
  blockAllPreventive = false;
  blockAllReactive = false;
  blockByTags: string[] = [];
  blockByName: string[] = [];

  /** Constructor creating object from interface **/
  constructor(data:IBlockEffect) {
    super(data);
    this.blockReason = data.blockReason;
    if (data.blockAllPreventive != undefined) {
      this.blockAllPreventive = data.blockAllPreventive;
    }
    if (data.blockAllReactive != undefined) {
      this.blockAllReactive = data.blockAllReactive;
    }
    if (data.blockByTags != undefined) {
      this.blockByTags = data.blockByTags;
    }
    if (data.blockByName != undefined) {
      this.blockByName = data.blockByName;
    }
  }
}

/** typeguard for blockEffect */
export function isBlockEffect(effect:Effect): effect is BlockEffect {
  return (effect as BlockEffect).blockAllReactive != undefined
  ;
}

export default BlockEffect;
