import {action, makeObservable, observable} from 'mobx';
import {toast} from 'react-toastify';

/** Hack State for ThreatAttack **/
export default class HackState {
  wrongHacks=0;

  /** Constructor**/
  constructor() {
    makeObservable(this, {
      wrongHacks: observable,
      resetWrongHacks: action,
      handleFailedHack: action,
    });
  }
  /** Resets wrong hacks */
  resetWrongHacks() {
    this.wrongHacks = 0;
  }
  /** Increment wrong hacks counter and notify
   * user on multiple failures */
  handleFailedHack() {
    this.wrongHacks++;
    if (this.wrongHacks == 3) {
      toast.info('Versuchen Sie eventuell neue Bedrohungen zu suchen.',
          {autoClose: 3000});
    }
  }
}

