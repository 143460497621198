import React from 'react';
import './EffectView.scss';

import Effect from 'src/components/SecurityCards/logic/effects/Effect';
import {TagIcons} from 'src/api/SecurityCards/SecurityEventsApi';
import ProbabilityEffect, {isProbabilityEffect}
  from '../logic/effects/ProbabilityEffect';

interface EffectViewProps {
    effect: Effect,
}

/** Component to render single effect, currently supports ProbabilityEffects */
export default class EffectView extends React.Component<EffectViewProps> {
    createTooltip = () =>{
      if (isProbabilityEffect(this.props.effect)) {
        return `${this.props.effect.probability > 0 ? 'erhöht': 'senkt'}
        ${this.props.effect.tag} Resistenz um
        ${Math.abs(this.props.effect.probability)}%`;
      }
    }

    /** render function */
    render() {
      // add cases for cardeffect
      const src = TagIcons[(this.props.effect as ProbabilityEffect).tag];
      return (<div className='EffectView'>
        <img src={src}/>
        <div className='overlay'>
          <div className='duration'>{this.props.effect.duration >0?
          this.props.effect.duration:'∞'}&#8987;
          </div>
        </div>
        <span className="ToolTip">{this.createTooltip()}</span>
      </div>);
    }
}
