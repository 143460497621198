import {observer} from 'mobx-react';
import React from 'react';
import './ChallengeView.scss';
import ChallengeActivation from './ChallengeActivation/ChallengeActivation';
import GameState from '../logic/GameState';
import * as ThreatModels from 'src/assets/ThreatAttack/ThreatModels';

interface ChallengeViewProps {
  gameState: GameState;
}

/**
 * The Challenge View
 */
@observer
class ChallengeView extends React.Component<ChallengeViewProps> {
  /** Default constructor **/
  constructor(props: ChallengeViewProps) {
    super(props);
  }
  /** Get the Threat Model diagram of the clicked challenge */
  private getThreatModelDiagram() {
    if (this.props.gameState.clickedChallenge != undefined) {
      for (const key in ThreatModels) {
        if (key == this.props.gameState.clickedChallenge.id) {
          return <img src={ThreatModels[key as keyof typeof ThreatModels]}
            className='ThreatModelDiagram'></img>;
        }
      }
    }
  }

  /** Render Function **/
  render() {
    const challengeDescriptionStyle = `.animateChallengeDesc{
      background:
        linear-gradient(-90deg,#000000 0,#0000 0) 10px 0,
        linear-gradient(#000000 0 0) 0 0;
      background-size: calc(
        ${this.props.gameState.clickedChallenge?.description.length}
        * 1ch) 200%;
      animation:
        b .7s infinite steps(1),
        t calc(${this.props.gameState.clickedChallenge?.description.length}
          * .025s) steps(
            ${this.props.gameState.clickedChallenge?.description.length})
          forwards;
      }`;
    return (
      <div className="ChallengeView rightSideView ThreatContainer">
        <h2>
          {this.props.gameState.clickedChallenge?.title}
        </h2>
        <style>{challengeDescriptionStyle}</style>
        <div key={this.props.gameState.clickedChallenge?.id}
          className='clickedChallengeContainer'>
          <div>
            {this.getThreatModelDiagram()}
          </div>
          <div className='clickedChallengeDesc'>
            <span className='animateChallengeDesc'>
              {this.props.gameState.clickedChallenge?.description}
            </span>
          </div>
        </div>
        <div className="ActivationContainer">
          <ChallengeActivation gameState={this.props.gameState}/>
        </div>
      </div>
    );
  }
}

export default ChallengeView;
