import {observer} from 'mobx-react';
import React from 'react';
import MailBodyView from './MailBodyView/MailBodyView';
import MailHeaderView from './MailHeaderView/MailHeaderView';
import './QuizContainer.scss';
import GameState from '../../logic/GameState';


interface QuizContainerProps {
  gameState: GameState;
}

/** A Container to display the email and some buttons
 * for the game controls
 **/
@observer
class QuizContainer extends React.Component<QuizContainerProps> {
  /** Constructor setting the props make sure mail is not
   * user defined to avoid xss!
   **/
  constructor(props: QuizContainerProps) {
    super(props);
  }


  /** Render function */
  render() {
    if (! this.props.gameState.currentMail) {
      return <div className="Empty">Wählen Sie eine E-Mail</div>;
    }

    let buttons;
    if (this.props.gameState.activeFeedback) {
      buttons = <div className="QuizButtons">
        <button id="continueBtn" className="ContinueButton DesignBTN"
          onClick={() => this.props.gameState.finishReview()}>Weiter</button>
      </div>;
    } else {
      buttons = <div className="QuizButtons">
        <button className="HintButton DesignBTN"
          onClick={() => this.props.gameState.showHints()}
        >Tipps Anzeigen</button>
        <button className="PhishingButton DesignBTN"
          onClick={() => this.props.gameState.submit(true)}>Phishing</button>
        <button className="NoPhishingButton DesignBTN"
          onClick={() => this.props.gameState.submit(false)}>
          Kein Phishing</button>
      </div>;
    }

    return (
      <div className="QuizContainer">
        <div className="QuizContainerHead">
          {buttons}
          <MailHeaderView gameState={this.props.gameState}/>
        </div>
        <MailBodyView
          gameState={this.props.gameState}
        />
      </div>
    );
  }
}

export default QuizContainer;
