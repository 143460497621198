import React from 'react';
import Overlay from '../Overlay/Overlay';
import './HintWidget.scss';

interface HintWidgetProps {
  toolTip?: string,
  inCard?: boolean,
  hintText: string
}


/** generic hint icon with toolTip as optional hover element and
 * hintText as overlay
 */
export default class HintWidget extends React.Component<HintWidgetProps> {
  toolTip = '';
  overlay: React.RefObject<Overlay>;
  /** default constructor */
  constructor(props: HintWidgetProps) {
    super(props);
    this.overlay = React.createRef<Overlay>();
  }

  /** default render */
  render() {
    return (<div className={`HintWidget${this.props.inCard?' FixedPosition':
    ''}`}
    onClick={(event)=>{
      event.stopPropagation();
      this.overlay.current?.toggle();
    }}>
      i
      <span className={this.props.toolTip?'ToolTip':'NoToolTip'}>
        {this.props.toolTip}</span>
      <Overlay visible={false} ref={this.overlay}>
        {this.props.hintText}
        <br/>
        <button className='DesignBTN neutralBTN'
          onClick={()=>this.overlay.current?.toggle()}>Ok</button>
      </Overlay>
    </div>);
  }
}
