import Event from './cards/Event';
import Skill from './cards/Skill';
import Effect from './effects/Effect';
import {getEvents} from 'src/api/SecurityCards/SecurityEventsApi';
import {getSkills} from
  'src/api/SecurityCards/SecuritySkillsApi';
import {getLevels, ILevel} from 'src/api/SecurityCards/LevelsApi';
import {getRandomBetween} from 'src/utils/Random/random';
import {
  getEffects, ICardEffect,
  IIncomeEffect, IBlockEffect, isIBlockEffect,
  IProbabilityEffect,
} from 'src/api/SecurityCards/EffectsApi';
import CardEffect from './effects/CardEffect';
import ProbabilityEffect from './effects/ProbabilityEffect';
import IncomeEffect from './effects/IncomeEffect';
import BlockEffect from './effects/BlockEffect';
import data from 'src/assets/SecurityCards/data.json';


/** Class for storing feched game data **/
export default class GameData {
  events = new Map<string, Event>();
  skills = new Map<string, Skill>();
  effects = new Map<string, Effect>();
  levels: ILevel[] = [];
  initialized = false;
  loadedLevel: ILevel | undefined;


  /** get a skill by name **/
  getSkill(name: string): Skill {
    const skill = this.skills.get(name);
    if (skill) {
      return skill;
    } else {
      throw Error(`Skill does not exist: ${name}`);
    }
  }

  /** get an event by name **/
  getEvent(name: string): Event {
    const event = this.events.get(name);
    if (event) {
      return event;
    } else {
      throw Error(`Event does not exist: ${name}`);
    }
  }

  /** get an effect by name **/
  getEffect(name: string): Effect {
    const effect = this.effects.get(name);
    if (effect) {
      // shallow copy suffices as effect only contains primitives
      return Object.assign({}, effect);
    } else {
      throw Error(`Effect does not exist: ${name}`);
    }
  }

  /** load levels **/
  async loadLevels() {
    this.levels = await getLevels();
    this.initialized = true;
  }

  /** load level **/
  async loadLevel(level: ILevel) {
    this.loadedLevel = level;
    // Load Harmless Events
    let prefix = 'harmless/';
    let events = await getEvents(prefix, level.harmlessEventNames);
    for (const event of events) {
      event.category = 'harmless';
      this.events.set(event.name, new Event(event));
    }
    // Incident Events
    prefix = 'incidents/';
    events = await getEvents(prefix, level.incidentEventNames);
    for (const event of events) {
      event.category = 'incident';
      this.events.set(event.name, new Event(event));
    }
    // Damaging Events
    prefix = 'damaging/';
    events = await getEvents(prefix, level.damagingEventNames);
    for (const event of events) {
      event.category = 'damaging';
      this.events.set(event.name, new Event(event));
    }

    await this.load();
  }

  /** load the static game data asynchronous **/
  async load() {
    // Load Skills
    const skills = await getSkills(data.skills);
    for (const skill of skills) {
      this.skills.set(
          skill.name,
          new Skill(skill),
      );
    }

    /** Load the effects and cast to the corresponding types of the effects. **/
    const effects = await getEffects(data.effects);
    for (const effect of effects) {
      if ((effect as ICardEffect).skillName !== undefined) {
        const skill = this.skills.get((effect as ICardEffect).skillName);
        if (skill !== undefined) {
          this.effects.set(effect.name,
              new CardEffect(effect as ICardEffect, skill));
        }
      } else if ((effect as IProbabilityEffect).probability !== undefined) {
        this.effects.set(effect.name,
            new ProbabilityEffect(effect as IProbabilityEffect));
      } else if ((effect as IIncomeEffect).amount !== undefined) {
        this.effects.set(effect.name,
            new IncomeEffect(effect as IIncomeEffect));
      } else if (isIBlockEffect(effect)) {
        this.effects.set(effect.name,
            new BlockEffect(effect as IBlockEffect));
      }
    }

    /** Add Skills to effects. **/
    this.skills.forEach((value: Skill) => {
      const skill: Skill = value;
      const effects = [];
      for (const name of value.effectNames) {
        const effect = this.getEffect(name);
        effect.skillName = skill.name;
        effects.push(effect);
      }
      skill.effects = effects;
    });

    /** Initialize the names of the effects for the events. **/
    this.events.forEach((value: Event) => {
      if (value.effectNames) {
        const effects = [];
        for (const name of value.effectNames) {
          const effect = this.getEffect(name);
          effect.skillName = value.label;
          effects.push(effect);
        }
        value.effects = effects;
      }
    });
  }

  /**
   * Creates "upcomming events", a list of event names,
   * with the maximum lengh of MAX_ROUNDS.
  */
  getUpcomingEvents() {
    if (this.loadedLevel == undefined) {
      throw Error('level is undefined');
    }

    const incidents = this.loadedLevel.incidentEventNames;
    const damaging = this.loadedLevel.damagingEventNames;
    const harmless = this.loadedLevel.harmlessEventNames;

    const upcomingEvents: string[] = [];

    // grace period
    const GRACE_PERIOD = 2;
    const graceEvents = [];
    for (let times = GRACE_PERIOD; times > 0; times--) {
      const index = getRandomBetween(0, harmless.length);
      graceEvents.push(harmless[index]);
      harmless.splice(index, 1);
    }
    // shuffle harmless events
    for (let times = harmless.length; times >0; times--) {
      const index = getRandomBetween(0, harmless.length);
      upcomingEvents.push(harmless[index]);
      harmless.splice(index, 1);
    }
    // random insert damaging events
    while (damaging.length > 0) {
      const index = getRandomBetween(0, upcomingEvents.length);
      const event = damaging.pop();
      if (event) {
        upcomingEvents.splice(index, 0, event);
        harmless.splice(index, 1);
      }
    }

    const numIncidents = incidents.length;
    while (incidents.length > 0) {
      if (incidents.length < numIncidents / 2) {
        const index = getRandomBetween(0, upcomingEvents.length/2);
        const event = incidents.pop();
        if (event) {
          upcomingEvents.splice(index, 0, event);
        }
      } else {
        const index = getRandomBetween(
            upcomingEvents.length/2, upcomingEvents.length);
        const event = incidents.pop();
        if (event) {
          upcomingEvents.splice(index, 0, event);
        }
      }
    }
    // add "grace" events
    upcomingEvents.push(...graceEvents);
    return upcomingEvents;
  }
}
