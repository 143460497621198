import React from 'react';
import './Overlay.scss';

/** Define to take Child nodes this is handled via the props **/
interface OverlayProps {
  children?: React.ReactNode,
  visible?: boolean,
}

interface OverlayState {
  visible: boolean
}

/** Overlay class providing a tansluciant overlay over the whole
 * element, it is embedded into. It takes HTML Children,
 * which are displayed in the overlay.
 */
class Overlay extends React.Component<OverlayProps, OverlayState> {
  /** constructor taking child elements **/
  constructor(props: OverlayProps) {
    super(props);
    this.state = {
      visible: this.props.visible == true,
    };
  }

  /** Overwrite state with component **/
  componentDidUpdate(prevProps: OverlayProps) {
    if (prevProps.visible != this.props.visible) {
      this.setState({visible: this.props.visible == true});
    }
  }

  /** toggle the overlay visibility **/
  toggle = () => {
    this.setState({visible: !this.state.visible});
  }

  /** Render the overlay **/
  render() {
    return (
      <div className="Overlay" style={
        {display: this.state.visible ? 'block' : 'none'}}>
        <div className="Content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Overlay;
