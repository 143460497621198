import {getJSON} from 'src/utils/Requests/Requests';

/** ILevel interface representing any kind of level **/
export interface ILevel {
  title: string;
  name: string;
  subTitle: string;
  description: string[];
  rounds: number;
  incidentEventNames: string[];
  damagingEventNames: string[];
  harmlessEventNames: string[];
}

/** API prefix **/
const PATH = '/SecurityCards/api/levels.json';

/** fetch effect jsons from server and return array of effect obje8/9*cts */
export async function getLevels(): Promise<ILevel[]> {
  const levels: ILevel[] = await getJSON(PATH);
  return levels;
}
