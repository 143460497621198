import React from 'react';
import './HeaderView.scss';
import Mail from '../logic/Mail';

interface HeaderViewProps {
  mail: Mail | undefined,
  close: () => void,
}

/** View the header of the mail in raw format **/
class HeaderView extends React.Component<HeaderViewProps> {
  /** Default constructor **/
  constructor(props: HeaderViewProps) {
    super(props);
  }

  /** Render the overlay **/
  render() {
    if (!this.props.mail) {
      return <div style={{display: 'none'}}></div>;
    }
    return (
      <div className="HeaderView">
        <div className="ContentWraper">
          <h2>Email Header</h2>
          <p>
            {this.props.mail.headers?
              this.props.mail.headers:
              'Keine Headerinformationen'
            }
          </p>
          <button className="DesignBTN"
            onClick={() => this.props.close()}>Schließen</button>
        </div>
      </div>
    );
  }
}

export default HeaderView;
