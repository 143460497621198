import React from 'react';
import './FinView.scss';


interface FinViewProps {
  score: number,
  maxScore: number,
  customContent?: JSX.Element
}

/**
 * A Class to view a generic finish page
 */
class FinView extends React.Component<FinViewProps> {
  /** Constructor taking the score as Number **/
  constructor(props: FinViewProps) {
    super(props);
  }

  /** Render The score **/
  render() {
    let content: JSX.Element = (<div></div>);
    if (this.props.customContent) {
      content = this.props.customContent;
    }

    return (
      <div className="FinView">
        <div className="FinScore">
          Erreichte Punktzahl: {this.props.score} / {this.props.maxScore}</div>
        {content}
        <button
          className="BtnOK DesignBTN neutralBTN"
          onClick={()=> window.open('https://www.umfrageonline.com/c/fmcg9rak')}>
          Zur Umfrage</button>
        <button
          className="BtnOK DesignBTN neutralBTN"
          onClick={()=> window.location.reload()}>
          Neustarten</button>
      </div>
    );
  }
}

export default FinView;
