/** Returns random number between range [min, max)
 */
export function getRandomBetween(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min)) + min;
}

/** shuffle the array in place **/
export function shuffleArray<T>(array: T[]): T[] {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex != 0) {
    randomIndex = getRandomBetween(0, currentIndex);
    currentIndex--;

    // Swapp the elements
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

/** Pop a random array from a list **/
export function popRandom<T>(array: T[]): T {
  const index = getRandomBetween(0, array.length);
  const value = array[index];
  array.splice(index, 1);
  return value;
}
