import {JsonMail, MailIssues} from 'src/api/JsonMailApi';
import {generateDayDateTimeString} from '../utils/displayLogic';

/** Class with all Mail informations **/
class Mail implements JsonMail {
    to: string;
    subject: string;
    date: Date;
    from: string;
    replyTo: string;
    body: string;
    attachment?: string | undefined;
    isPhishing: boolean;
    issueTags: MailIssues[] | undefined;
    headers: string | undefined;
    hintUsed: boolean;

    /** Constructor converts all JsonMail-Variables to Variables **/
    constructor(template: JsonMail) {
      this.to = template.to;
      this.subject = template.subject;
      this.date = template.date;
      this.from = template.from;
      this.replyTo = template.replyTo;
      this.body = template.body;
      this.attachment = template.attachment;
      this.isPhishing = template.isPhishing;
      this.issueTags = template.issueTags;
      this.headers = template.headers;
      this.hintUsed = false;
    }

    /** User bought hint**/
    useHint() {
      this.hintUsed = true;
    }

    /** Does this email has this issue?**/
    isIssueOfMail(issue: MailIssues) {
      return this.issueTags?.includes(issue);
    }

    /** return the date time as string **/
    get dateTimeAsString() {
      return generateDayDateTimeString(this.date);
    }

    /** Returns Email body as DOM-Tree**/
    getEmailBodyAsDOM() {
      const parser = new DOMParser();
      const bodyAsElement = parser.parseFromString(
          this.body, 'text/html').body;
      const hyperlinkElements = bodyAsElement.getElementsByTagName('a');

      Array.from(hyperlinkElements).forEach((linkElement) => {
        linkElement.classList.add('MailbodyLink');
      });
      return bodyAsElement;
    }
}
export default Mail;
