import {getJSON} from 'src/utils/Requests/Requests';

/** API Prefix **/
const PATH_PREFIX = '/ThreatAttack/api/threats/';

/** IThreat interface for deserialization **/
export interface IThreat {
    id: string;
    name: string;
}


/** function to get a threat object */
export async function getThreat(path: string) : Promise<IThreat> {
  const threat : IThreat = await getJSON(PATH_PREFIX + path);
  return threat;
}
