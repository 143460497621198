import {MailIssues} from 'src/api/JsonMailApi';
import Mail from '../logic/Mail';

const HINT_TEXTES: Map<MailIssues, string> = new Map([
  [MailIssues.links, 'Betrachten Sie die enthaltenen Links genau.'],
  [MailIssues.topic, 'Betrachten Sie den Betreff der E-Mail genau.'],
  [MailIssues.typos, 'Achten Sie auf Rechtschreibung und Grammatik.'],
  [MailIssues.address, `Die Absenderadresse gibt oft bereits Hinweise,
 achten Sie dabei insbesondere auch auf spezielle Unicodezeichen,
 welche gewöhnlichen Buchstaben ähnlich sehen.`],
  [MailIssues.pressure,
    'Übermäßig aufgebauter Druck kann ein Hinweis auf Phishing sein.'],
  [MailIssues.recipient,
    'Die Empfängeradresse(n) kann(können) bereits hinweise geben.'],
  [MailIssues.attachment, 'Seien Sie bei E-Mail Anhängen stets vorsichtig.'],
  [MailIssues.linkaddress, `Überprüfen Sie die Adresse von Links, in dem Sie mit
  Ihrer Maus über diese drüber fahren, ohne die Links dabei anzuklicken!`],
  [MailIssues.addressation,
    `Seien Sie bei ungewöhnlichen und besonders generischen
    Ansprachen vorsichtig.`],
]);

/** Get hint textes as array **/
export function getExplainTextes(mail: Mail): string[] {
  const data: string [] = [];
  let value: string;

  for (value in mail.issueTags) {
    if (mail.issueTags) {
      const parsed = parseInt(value);
      const text = HINT_TEXTES.get(mail.issueTags[parsed]);
      if (text) {
        data.push(text);
      }
    }
  }
  return data;
}
