import React from 'react';
import './FeedbackWidget.scss';
import bakIcon from 'src/assets/logo.svg';

interface FeedbackWidgetProps {
  position: number[],
  link: string,
  text?: string|undefined,
}

/** Feedback widget component to display the give us feedback sign. **/
class FeedbackWidget extends React.Component<FeedbackWidgetProps> {
  /** Default constructor with props **/
  constructor(props: FeedbackWidgetProps) {
    super(props);
  }

  /** default render function **/
  render() {
    return (
      <div className="FeedbackWidget" style={{
        left: `${this.props.position[0]}px`,
        top: `${this.props.position[1]}px`,
      }} onClick={()=>window.open(this.props.link)}>
        <img src={bakIcon}/>
        <span>{this.props.text ?? 'Geben Sie uns Feedback'}</span>
      </div>
    );
  }
}

export default FeedbackWidget;
