import {Tag} from 'src/api/SecurityCards/SecurityEventsApi';
import {IEvent} from 'src/api/SecurityCards/SecurityEventsApi';
import Effect from '../effects/Effect';
import GameState from '../GameState';

/** interface for securityevents*/
class Event implements IEvent {
  label: string;
  name: string;
  description: string;
  occurText: string;
  notOccurText: string;
  // Probability in percent to avoid floats
  probability;
  cost;
  tags: Tag[];
  effectNames?: string[] | undefined;
  requiredActions?: string[] | undefined;
  severity?: number | undefined;
  effects: Effect[] | undefined;
  occurs: boolean | undefined;
  hintText: string | undefined;
  category?:string|undefined;
  roll?:number|undefined

  /** Constructor creating an event from
   * an InterfaceEvent.
   */
  constructor(template: IEvent) {
    this.label = template.label;
    this.name = template.name;
    this.description = template.description;
    this.occurText = template.occurText;
    this.notOccurText = template.notOccurText;
    this.probability = template.probability;
    this.cost = template.cost;
    this.tags = template.tags;
    this.effectNames = template.effectNames;
    this.requiredActions = template.requiredActions;
    this.severity = template.severity;
    this.occurs = undefined;
    this.hintText = template.hintText;
    this.category = template.category;
  }

  /** Applies the effect of the event
   * to the passed gamestate.
   * subtracts eventcost and adds effects to gamestate.
   **/
  applyTo(gameState: GameState) {
    gameState.decreaseMoney(this.cost);
    gameState.activeEvent?.effects?.forEach(
        (effect) => gameState.activeEffects.addEffect(effect));
  }
}
export default Event;
